/* eslint-disable @typescript-eslint/ban-ts-comment */
import { defineStore } from "pinia"
import { HttpService } from "@/helpers/http-service"
import { CONSTANT } from "@/helpers/constant"
import { LoginUser } from "@/types/login"
import { router } from "@/router"
import _ from "lodash"
import { useCustomerStore } from "@/stores/customer.store"
import { RecoverPassword } from "@/models/recover.model"
import { RecoverCreatePassword } from "@/models/recoverCreate.model"
import { _getValue } from "@/helpers/common"
import { ApiResponse } from "@/types/apiResponse"
import { useSystemAndContextStore } from "@/stores/systemAndContext.store"
const httpService = new HttpService()

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    contextToken: localStorage.getItem("contextToken") || "",
    salesRepContextToken: localStorage.getItem("salesRepContextToken") || "",
    salesRepCustomer: JSON.parse(localStorage.getItem("salesRepCustomer")!),
    returnUrl: "",
    errorMessage: "",
    successMessage: "",
  }),
  actions: {
    isAnySalesRep() : boolean {
      const customerStore = useCustomerStore()
      const salesRep = _.get(customerStore.customer, "customFields.eecom_tp_is_sales_rep", false);
      const superSalesRep = _.get(customerStore.customer, "customFields.eecom_tp_is_super_sales_rep", false);
      return salesRep || superSalesRep;
    },
    async login(loginUserRequest: LoginUser) {
      try {
        const cookieConsent = localStorage.getItem("cookieConsent")
        const teamPortalLang = localStorage.getItem("teamportal-lang")
        const teamPortalLangId = localStorage.getItem("teamportal-lang-id")
        localStorage.clear()
        if (cookieConsent) {
          localStorage.setItem("cookieConsent", cookieConsent)
        }
        if (teamPortalLang) {
          localStorage.setItem("teamportal-lang", teamPortalLang)
        }
        if (teamPortalLangId) {
          localStorage.setItem("teamportal-lang-id", teamPortalLangId)
        }
        const result: ApiResponse = await httpService.post(`${CONSTANT.API.ACCOUNT_LOGIN}`, { ...loginUserRequest })
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          this.contextToken = _.get(result, "data.contextToken", "")
          if (this.contextToken) {
            localStorage.setItem("contextToken", this.contextToken)
            const customerStore = await useCustomerStore()
            await customerStore.fetchCustomerInfo()
            if (customerStore.errorMessage) this.errorMessage = `eecom-teamportal.error.customerIsNotLoggedInError`
          } else {
            this.errorMessage = `eecom-teamportal.error.loginError`
          }
        } else {
          this.errorMessage = `eecom-teamportal.error.loginError`
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          const currentError: any = errors[0]
          if (currentError.status === CONSTANT.HTTP_STATUS.UNAUTHORIZED.toString()) {
            this.errorMessage = `eecom-teamportal.error.loginError`
          } else {
            this.errorMessage = `eecom-teamportal.error.somethingWentWrongError`
          }
        }

        console.error(error)
      }
    },
    async logout() {
      try {
        const result: ApiResponse = await httpService.post(`${CONSTANT.API.ACCOUNT_LOGOUT}`)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          if (this.salesRepContextToken && this.salesRepCustomer) {
            const salesCustomerId = _getValue(this.salesRepCustomer, "id", "").value
            if (salesCustomerId) {
              await this.loginWithCustomerId(salesCustomerId, CONSTANT.LOGIN_TYPE.SALES_REP_CUSTOMER, false)
            }
          } else {
            const cookieConsent = localStorage.getItem("cookieConsent")
            const teamPortalLang = localStorage.getItem("teamportal-lang")
            const teamPortalLangId = localStorage.getItem("teamportal-lang-id")
            localStorage.removeItem("contextToken")
            localStorage.removeItem("customer")
            localStorage.clear()
            this.contextToken = ""
            const customerStore = await useCustomerStore()
            customerStore.customer = null
            if (cookieConsent) {
              localStorage.setItem("cookieConsent", cookieConsent)
            }
            if (teamPortalLang) {
              localStorage.setItem("teamportal-lang", teamPortalLang)
            }
            if (teamPortalLangId) {
              localStorage.setItem("teamportal-lang-id", teamPortalLangId)
            }
            await router.push(CONSTANT.ROUTE.PATH.LOGIN)
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async recoverPassword(recoverPassword: RecoverCreatePassword) {
      try {
        this.contextToken = ""
        const result: ApiResponse = await httpService.post(`${CONSTANT.API.ACCOUNT_RECOVER_PASSWORD}`, { ...recoverPassword })
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          if (_.get(result, "data.success", "")) {
            this.successMessage = `eecom-teamportal.success.recoverPasswordSuccess`
          } else {
            this.errorMessage = `eecom-teamportal.error.passwordRecoveryRequestError`
          }
        } else {
          this.errorMessage = `eecom-teamportal.error.passwordRecoveryRequestError`
        }
      } catch (error) {
        this.contextToken = ""
        this.errorMessage = `eecom-teamportal.error.passwordRecoveryRequestError`
        console.error(error)
      }
      this.contextToken = ""
    },
    async recoverPasswordConfirm(recoverPassword: RecoverPassword) {
      try {
        this.contextToken = ""
        const result: ApiResponse = await httpService.post(`${CONSTANT.API.ACCOUNT_RECOVER_PASSWORD_CONFIRM}`, { ...recoverPassword })
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          if (_.get(result, "data.success", "")) {
            return
          }
          this.errorMessage = `eecom-teamportal.error.passwordRecoveryConfirmError`
        } else {
          this.errorMessage = `eecom-teamportal.error.passwordRecoveryConfirmError`
        }
      } catch (error) {
        this.contextToken = ""
        this.errorMessage = `eecom-teamportal.error.passwordRecoveryConfirmError`
        const errors = _.get(error, "response.data.errors", [])
        for (let i = 0; i < errors.length; i++) {
          // @ts-ignore
          if (errors[i].code === "CHECKOUT__CUSTOMER_RECOVERY_HASH_EXPIRED") {
            this.errorMessage = `eecom-teamportal.error.passwordRecoveryConfirmErrorExpired`
            return
          }
        }
        console.error(error)
      }
      this.contextToken = ""
    },
    async setContextToken(token: string) {
      localStorage.setItem("", token)
      this.contextToken = token
    },
    async loginWithCustomerId(contactId: any, loginType?: string, isExternalRedirect?: boolean) {
      const apiUrl = CONSTANT.API.SALES_FORCE_CUSTOMER_ACCOUNT_LOGIN_WITH_CUSTOMER_ID
      try {
        const customerStore = await useCustomerStore()
        let requestData = {}
        if (loginType !== CONSTANT.LOGIN_TYPE.SALES_REP_CUSTOMER) {
          requestData = {
            loginType: loginType ?? null,
            customerId: contactId,
            salesRepId: customerStore.customer ? customerStore.customer.id : "",
            salesRepContextToken: customerStore.customer && loginType !== CONSTANT.LOGIN_TYPE.SALES_REP_CUSTOMER ? this.contextToken : "",
          }
        } else {
          requestData = {
            loginType: loginType ?? null,
            salesRepId: contactId,
          }
        }
        const systemAndContextStore = useSystemAndContextStore()
        const result: any = await httpService.post(`${apiUrl}`, requestData)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          if (this.isAnySalesRep() && loginType !== CONSTANT.LOGIN_TYPE.SALES_REP_CUSTOMER && !this.salesRepCustomer && !this.salesRepContextToken) {
            if (customerStore.customer) {
              this.salesRepCustomer = customerStore.customer
              localStorage.setItem("salesRepCustomer", JSON.stringify(this.salesRepCustomer))
            }
            if (this.contextToken) {
              this.salesRepContextToken = this.contextToken
              localStorage.setItem("salesRepContextToken", this.salesRepContextToken)
              localStorage.removeItem("contextToken")
              localStorage.removeItem("customer")
              this.contextToken = ""
              customerStore.customer = null
            }
          }
          systemAndContextStore.currentContext = null
          const { token } = result.data
          if (token) {
            this.contextToken = token
            localStorage.setItem("contextToken", this.contextToken)
          }
          if (this.contextToken) {
            if (loginType === CONSTANT.LOGIN_TYPE.SALES_REP_CUSTOMER) {
              if (this.salesRepContextToken) {
                this.salesRepContextToken = ""
                this.salesRepCustomer = null
                localStorage.removeItem("salesRepContextToken")
                localStorage.removeItem("salesRepCustomer")
              }
            }
            await customerStore.fetchCustomerInfo(isExternalRedirect)
            await systemAndContextStore.fetchCurrentContext()
            this.errorMessage = customerStore.errorMessage ? `eecom-teamportal.error.customerIsNotLoggedInError` : ""
          } else {
            this.errorMessage = `eecom-teamportal.error.loginError`
          }
        } else {
          this.errorMessage = `eecom-teamportal.error.customerIsNotLoggedInError`
        }
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          this.errorMessage = errors[0].detail
        }
        console.error(error)
      }
    },
  },
})
