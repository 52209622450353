import { CONSTANT } from "@/helpers/constant"

const auth = ({ contextToken, authedUser }: MiddlewareContext) => {

  if(!contextToken || !authedUser){
    return CONSTANT.ROUTE.NAME.LOGIN
  }

  return true
}

export default auth
