import {CONSTANT} from "@/helpers/constant";
import { auth, salesforce } from "@/router/middleware"

export default {
    path: CONSTANT.ROUTE.PATH.CAMPAGIN,
    name: CONSTANT.ROUTE.NAME.CAMPAGIN,
    redirect: CONSTANT.ROUTE.PATH.DASHBOARD_SALES_FORCE,
    meta: {
        middleware: [auth, salesforce]
    },
}



