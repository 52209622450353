import {CONSTANT} from "@/helpers/constant";
import { guest } from "@/router/middleware";

const loginPageTitle = `${process.env['VUE_APP_NAME']} | ${process.env['VUE_APP_LOGIN_PAGE_TITLE']}`;
const loginPageDescription = process.env['VUE_APP_LOGIN_PAGE_DESCRIPTION'];
export default {
    path: '/',
    redirect: '/login',
    meta: {
      middleware: [guest]
    },
    children: [
      {
        path: CONSTANT.ROUTE.PATH.LOGIN,
        component: () => import('@/views/login/LoginPage.vue'),
        name: 'login',
        meta: {title: loginPageTitle, description: loginPageDescription}
      },
        {
            path: CONSTANT.ROUTE.PATH.PASSWORD_RECOVERY,
            component: () => import('@/views/login/PasswordRecovery.vue'),
            name: 'passwordRecovery'
        },
        {
            path: CONSTANT.ROUTE.PATH.PASSWORD_RECOVERY_CREATE_NEW,
            component: () => import('@/views/login/PasswordRecoveryCreateNew.vue'),
            name: 'passwordRecoveryCreateNew'
        },
    ]
};

