/* eslint-disable @typescript-eslint/ban-ts-comment */
import { defineStore } from "pinia"
import { HttpService } from "@/helpers/http-service"
import { CONSTANT } from "@/helpers/constant"
import _ from "lodash"
import { router } from "@/router"
import { salesForceCustomerDataSaveButton } from "@/helpers/behavior-service"
import { _getValue } from "@/helpers/common"
import { switchLocale } from "@/helpers/language"
import { Customer } from "@/types/customer"
import { ApiResponse } from "@/types/apiResponse"
import { Color } from "@/types/color"

const httpService = new HttpService()

interface State {
  customer: Customer | null
  customerData: Customer
  customerColor: Color[]
  errorMessage: string
}

export const useCustomerStore = defineStore({
  id: "customer",
  state: (): State => ({
    customer: JSON.parse(localStorage.getItem("customer")!),
    customerData: {} as Customer,
    customerColor: [],
    errorMessage: "",
  }),
  actions: {
    async fetchCustomerInfo(isExternalRedirect?: boolean) {
      try {
        const result: ApiResponse = await httpService.post(`${CONSTANT.API.ACCOUNT_CUSTOMER}`, { associations: CONSTANT.BODY_PARAMS.ASSOCIATIONS.ASSOCIATIONS_CUSTOMER })

        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const data = _.get(result, "data", null)
          if (data) {
            this.customer = data as Customer
            localStorage.setItem("customer", JSON.stringify(result.data))
            // await switchLocale(_getValue(data, "language.translationCode.code", "en").value)
            const isSalesRep = _.get(data, "customFields.eecom_tp_is_sales_rep", false) || _.get(data, "customFields.eecom_tp_is_super_sales_rep", false);

            const redirectUrl = isSalesRep ? CONSTANT.ROUTE.PATH.DASHBOARD_SALES_FORCE : CONSTANT.ROUTE.PATH.DASHBOARD_CUSTOMER
            if (!isExternalRedirect) {
              await router.push(redirectUrl)
            }
          }
        } else {
          this.errorMessage = `eecom-teamportal.error.customerIsNotLoggedInError`
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          this.errorMessage = errors[0].detail
        }
        console.error(error)
      }
    },
    async fetchCurrentCustomer() {
      try {
        const result: ApiResponse = await httpService.post(`${CONSTANT.API.ACCOUNT_CUSTOMER}`, { associations: CONSTANT.BODY_PARAMS.ASSOCIATIONS.ASSOCIATIONS_CUSTOMER })

        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const data = _.get(result, "data", null)
          if (data) {
            this.customer = data as Customer
            this.customerData = data as Customer
            localStorage.setItem("customer", JSON.stringify(result.data))
          }
        } else {
          this.errorMessage = `eecom-teamportal.error.customerIsNotLoggedInError`
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          this.errorMessage = errors[0].detail
        }
        console.error(error)
      }
    },
    async createCustomerInfo(customerInfo: any) {
      try {
        const result: ApiResponse = await httpService.post(`${CONSTANT.API.SALES_FORCE_CUSTOMER_CREATE}`, customerInfo)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const data: any = _.get(result, "data", null)
          if (data) {
            salesForceCustomerDataSaveButton.clearSaveButtonIsClicked()
            await router.push({ name: CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_BASE, params: { contactId: data?.id }, query: { edit: "false" } })
          }
        } else {
          this.errorMessage = `eecom-teamportal.error.customerIsNotLoggedInError`
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          this.errorMessage = errors[0].detail
        }
        console.error(error)
      }
    },
    async updateCustomerInfo(customerInfo: any, customerId: string) {
      try {
        const patchCustomerUrl = CONSTANT.API.SALES_FORCE_CUSTOMER_EDIT.replace("{id}", customerId)
        const result: ApiResponse = await httpService.patch(patchCustomerUrl, customerInfo)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const data = _.get(result, "data", null)
          if (data) {
            salesForceCustomerDataSaveButton.clearSaveButtonIsClicked()
            await router.push({ name: CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_BASE, params: { contactId: customerId }, query: { edit: "false" } })

            // name: CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_BASE, params: { contactId: props.contactId }, query: { edit: isEditEnabled }
          }
        } else {
          this.errorMessage = `eecom-teamportal.error.customerIsNotLoggedInError`
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          this.errorMessage = errors[0].detail
        }
        console.error(error)
      }
    },
    async fetchCustomerColorFilter() {
      try {
        const result: ApiResponse = await httpService.get(CONSTANT.API.CUSTOMER_COLOR_FILTER)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const colorList: Color[] = _getValue(result, "data.color", []).value
          if (colorList && colorList.length > 0) {
            colorList.forEach((color: any) => {
              if (!color.name) {
                color.name = _getValue(color, "translated.name", "").value
              }
            })
            this.customerColor = colorList
          } else {
            this.customerColor = []
          }
        }
      } catch (error) {
        const errors = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          // @ts-ignore
          this.errorMessage = errors[0].detail
        }
        console.error(error)
      }
    },
  },
})
