import { CONSTANT } from "@/helpers/constant"
import { auth, customer } from "@/router/middleware"

export default {
  path: "/",
  component: () => import("@/views/customer/CustomerIndex.vue"),
  meta: {
    middleware: [auth, customer]
  },
  children: [
    {
      path: CONSTANT.ROUTE.PATH.DASHBOARD_CUSTOMER,
      name: CONSTANT.ROUTE.NAME.DASHBOARD_CUSTOMER,
      component: () => import("@/views/customer/CustomerDashboard.vue"),
    },
    {
      path: CONSTANT.ROUTE.PATH.TEAM_ORDER,
      name: CONSTANT.ROUTE.NAME.TEAM_ORDER,
      component: () => import("@/views/customer/CustomerTeamOrderV2.vue"),
    },
    {
      path: CONSTANT.ROUTE.PATH.OFFER,
      name: CONSTANT.ROUTE.NAME.OFFER,
      component: () => import("@/views/customer/CustomerOffer.vue"),
    },
    {
      path: CONSTANT.ROUTE.PATH.ORDER_DETAILS,
      name: CONSTANT.ROUTE.NAME.ORDER_DETAILS,
      component: () => import("@/views/customer/CustomerOrderDetails.vue"),
    },
    {
      path: CONSTANT.ROUTE.PATH.ORDERS,
      name: CONSTANT.ROUTE.NAME.ORDERS,
      component: () => import("@/views/customer/CustomerOrders.vue"),
    },
    {
      path: CONSTANT.ROUTE.PATH.CONTRACTS_DOCUMENTS,
      name: CONSTANT.ROUTE.NAME.CONTRACTS_DOCUMENTS,
      component: () => import("@/views/customer/CustomerContractsAndDocuments.vue"),
    },
    // {
    //     path: CONSTANT.ROUTE.PATH.MEDIA,
    //     name: CONSTANT.ROUTE.NAME.MEDIA,
    //     component: () => import('@/views/customer/CustomerMedia.vue'),
    // },
    {
      path: CONSTANT.ROUTE.PATH.CHECKOUT,
      name: CONSTANT.ROUTE.NAME.CHECKOUT,
      component: () => import("@/views/customer/CustomerCheckout.vue"),
    },
  ],
}
