import {CONSTANT} from "@/helpers/constant";
import { auth } from "@/router/middleware";

export default {
    path: CONSTANT.ROUTE.PATH.MY_ACCOUNT,
    name: CONSTANT.ROUTE.NAME.MY_ACCOUNT,
    meta: {
        middleware: [auth]
    },
    component: () => import('@/views/account/MyAccount.vue'),
}



