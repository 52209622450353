import { RouteLocationRaw } from "vue-router"

export { default as guest } from "@/router/middleware/guest"
export { default as auth } from "@/router/middleware/auth"
export { default as salesforce } from "@/router/middleware/salesforce"
export { default as customer } from "@/router/middleware/customer"

export const resolveMiddleware = async (middleware: MiddlewareFunction[], context: MiddlewareContext, index = 0): Promise<boolean | RouteLocationRaw | void> => {
  if (index >= middleware.length) return true // Proceed if all middleware pass
  const currentMiddleware = middleware[index]

  const result = await currentMiddleware(context)

  if (result === false || typeof result === "string" || (result && typeof result === "object")) {
    return result // Return `false` (cancel navigation) or `RouteLocationRaw` (redirect)
  }

  return resolveMiddleware(middleware, context, index + 1) // Proceed to next middleware
}
