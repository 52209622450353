import axios, { AxiosInstance, ResponseType } from "axios"
import { useAuthStore, useCustomerStore, useSystemAndContextStore } from "@/stores"
// @ts-ignore
import _ from "lodash"
import { useLoading } from "vue-loading-overlay"
import { CONSTANT } from "@/helpers/constant"
import { _getValue, logoutFromBrowser } from "@/helpers/common"

export class HttpService {
  private axios: AxiosInstance
  private isReqInProgress: boolean = false

  constructor(useLoader: boolean = true) {
    let serviceCount = 0
    const $loading = useLoading()
    let loader: any
    this.axios = axios.create({
      baseURL: process.env.VUE_APP_SW_URL,
      timeout: parseInt(process.env.VUE_APP_HTTP_CLIENT_TIMEOUT || "30000"),
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "sw-access-key": process.env.VUE_APP_SW_ACCESS_KEY,
      },
    })
    // Set default headers for all axios requests
    axios.defaults.headers.common["sw-access-key"] = process.env.VUE_APP_SW_ACCESS_KEY

    // Add a request interceptor
    this.axios.interceptors.request.use(
      async (config) => {
        // console.log("serviceCount",serviceCount);
        // serviceCount++;
        // console.log("serviceCount:after",serviceCount);
        // if(serviceCount > 0) loader = $loading.show();
        // Do something before request is sent
        if (!this.isReqInProgress) {
          this.isReqInProgress = true
          loader = useLoader && $loading.show()
        }
        // Set the sw-access-key header if it is not already set
        if (config.headers && !config.headers["sw-access-key"]) {
          config.headers["sw-access-key"] = process.env.VUE_APP_SW_ACCESS_KEY
        }
        if (config.url?.includes("login") || config.url?.includes("account/recovery-password") || config.url?.includes("eecom-teamportal/context-token")) {
          const authStore = useAuthStore()
          const contextToken = authStore.contextToken
          if (contextToken) {
            // @ts-ignore
            config.headers["sw-context-token"] = contextToken
          } else {
            // @ts-ignore
            config.headers["sw-context-token"] = process.env.VUE_APP_SW_ACCESS_KEY
          }
        } else {
          try {
            const authStore = useAuthStore()
            const customerStore = useCustomerStore()
            const systemAndContextStore = useSystemAndContextStore()
            const languageList = systemAndContextStore.getLanguages
            const defaultLanguage = systemAndContextStore.getDefaultLanguage
            let langId = localStorage.getItem("teamportal-lang-id") as string

            if (!langId || langId === "undefined") {
              // @ts-ignore
              langId = _.get(defaultLanguage, "id", "")
            }

            if (langId) {
              // @ts-ignore
              config.headers["sw-language-id"] = langId
            }
            // if (customerStore.customer && customerStore.customer.languageId) {
            //     // @ts-ignore
            //     config.headers["sw-language-id"] = customerStore.customer.languageId;
            // }
            const contextToken = authStore.contextToken
            if (contextToken) {
              // @ts-ignore
              config.headers["sw-context-token"] = contextToken
            }
          } catch (e: any) {
            console.error(e.message)
          }
        }
        return config
      },
      (error) => {
        this.loaderHide(loader)
        console.debug(error) // for debug
        return Promise.reject(error)
      }
    )

    // Add a response interceptor
    this.axios.interceptors.response.use(
      (response) => {
        // if(serviceCount > 0) {
        //     serviceCount--;
        // }
        // if(serviceCount === 0){
        //     this.loaderHide(loader);
        // }
        this.loaderHide(loader)
        /*
              this doesn't worke because shopware does not save the new contextToken if token is expired

                        const authStore = useAuthStore();


                        const contextToken = _.get(response.headers,'sw-context-token',null);
                        console.log(contextToken);
                        if ( contextToken && contextToken !== 'undefined' && contextToken !== authStore.contextToken ){
                            authStore.contextToken = contextToken;
                            localStorage.setItem('contextToken', contextToken);
                        }

             */
        // Do something with response data
        return response
      },
      async (error) => {
        const errorObj = _.get(error, "response.data.errors[0]", undefined)
        this.loaderHide(loader)
        const status = _.get(errorObj, "status", undefined) // (error && error.response && error.response.status) || undefined
        const code = _.get(errorObj, "code", undefined)
        if (status === CONSTANT.ERROR.ERROR_CODE.UNAUTHORIZED) {
          // await logoutFromBrowser();
        } else if (status === CONSTANT.ERROR.ERROR_CODE.FORBIDDEN) {
          if (code === CONSTANT.ERROR.CODE.CHECKOUT__CUSTOMER_NOT_LOGGED_IN) {
            const customerStore = useCustomerStore()
            let contactId = null
            if (customerStore.customer) {
              contactId = _getValue(customerStore.customer, "id", null).value
              const isSalesRep = _.get(customerStore.customer, "customFields.eecom_tp_is_sales_rep", false)
              const type = isSalesRep ? CONSTANT.LOGIN_TYPE.SALES_REP_CUSTOMER : undefined
              if (contactId) {
                const authStore = useAuthStore()
                await authStore.loginWithCustomerId(contactId, type, true)
                try {
                  // Retry the request with the updated headers
                  return this.axios.request(error.config)
                } catch (error) {
                  await logoutFromBrowser()
                  return Promise.reject(error)
                }
              }
            }
          }
          await logoutFromBrowser()
        } else {
          return Promise.reject(error)
        }
      }
    )
  }

  public get<T>(url: string, params?: any, responseType?: ResponseType | undefined): Promise<T> {
    return this.axios.get(url, { 
      params,
      responseType: responseType ?? undefined,
    })
  }

  public post<T>(url: string, data?: any, extra?: any): Promise<T> {
    return this.axios.post(url, data, extra)
  }

  public patch<T>(url: string, data?: any): Promise<T> {
    return this.axios.patch(url, data)
  }

  public put<T>(url: string, data?: any): Promise<T> {
    return this.axios.put(url, data)
  }

  public delete<T>(url: string, params?: any): Promise<T> {
    return this.axios.delete(url, { params })
  }

  private loaderHide = (loader: any) => {
    if (this.isReqInProgress && loader) {
      this.isReqInProgress = false
      loader.hide()
    }
  }
}
