/* eslint-disable no-useless-escape */
export const CONSTANT = {
  API: {
    ACCOUNT_LOGIN: "/account/login",
    ACCOUNT_LOGOUT: "/account/logout",
    ACCOUNT_CUSTOMER: "/account/customer",
    ACCOUNT_CHANGE_PASSWORD: "/account/change-password",
    ACCOUNT_UPDATE_LANGUAGE: "/eecom-teamportal/account/change-language",
    //ACCOUNT_RECOVER_PASSWORD: '/account/recovery-password',
    ACCOUNT_RECOVER_PASSWORD: "/eecom-teamportal/account/recovery-password",
    ACCOUNT_RECOVER_PASSWORD_CONFIRM: "/account/recovery-password-confirm",
    ORDER: "/order",
    CHECKOUT_INIT: "/eecom-teamportal/context-token-to-cart",
    SALES_FORCE_CUSTOMER_ACCOUNT_LOGIN_WITH_CUSTOMER_ID: "/eecom-teamportal/login-with-customerId",
    SALES_REP_ORDER: "/eecom-teamportal/sales-rep/customer/{id}/order",
    SALES_REP_ORDER_BY_ORDER_ID: "/eecom-teamportal/sales-rep/customer/{id}/order/{orderId}",
    SALES_FORCE_CUSTOMER_LIST: "/eecom-teamportal/sales-rep/customer-list",
    SALES_FORCE_CUSTOMER_LIST_DELETE: "/eecom-teamportal/sales-rep/customer/{id}/delete",
    SALES_FORCE_CUSTOMER_LIST_DUPLICATE: "/eecom-teamportal/sales-rep/customer/{contactId}/duplicate",
    SALES_FORCE_CUSTOMER_LIST_CLONE: "/eecom/clone/{entity}/{id}",
    SALES_FORCE_CUSTOMER_LIST_DELETE_ALL: "/eecom-teamportal/sales-rep/customer/deleteAll",
    SALES_FORCE_CUSTOMER_LIST_MEDIA: "/eecom-teamportal/sales-rep/customer/{id}/media",
    SALES_FORCE_CUSTOMER_CREATE: "/eecom-teamportal/sales-rep/customer",
    SALES_FORCE_CUSTOMER_EDIT: "/eecom-teamportal/sales-rep/customer/{id}",
    SALES_FORCE_CUSTOMER_UPLOAD_MEDIA: "/eecom-teamportal/sales-rep/customer/media/upload",
    SALES_REP_PRODUCT_RANGE: "/eecom-teamportal/assortment/{id}",
    CLONE_PRODUCT_RANGE: "/eecom/clone/{entity}/{id}",
    UPDATE_PRODUCT_RANGE: "/eecom-teamportal/assortment/{id}/update",
    DELETE_PRODUCT_RANGE: "/eecom-teamportal/assortment/{id}/delete",
    UPDATE_PRODUCT_RANGE_BY_CUSTOMER: "/eecom-teamportal/assortment/update/{customerId}",
    CUSTOMER_COLOR_FILTER: "/eecom-teamportal/sales-rep/customer-color-filter",
    IMPRINT_PAGE: "/eecom-teamportal/imprint/cms",
    GET_CHECKOUT_CONTEXT: "/eecom-teamportal/get-checkout-context",
    ADDRESS: {
      LIST_ADDRESS: "/eecom-teamportal/sales-rep/customer/{id}/list-address",
      DELETE_ADDRESS: "/eecom-teamportal/sales-rep/customer/{id}/address/{addressId}",
      PATCH_ADDRESS: "/eecom-teamportal/sales-rep/customer/{id}/address/{addressId}",
      PATCH_DEFAULT_SHIPPING_ADDRESS: "/eecom-teamportal/sales-rep/customer/{id}/address/default-shipping/{addressId}",
      PATCH_DEFAULT_BILLING_ADDRESS: "/eecom-teamportal/sales-rep/customer/{id}/address/default-billing/{addressId}",
      CREATE_NEW_ADDRESS: "/eecom-teamportal/sales-rep/customer/{id}/address",
    },
    DOCUMENT: {
      LIST_DOCUMENT: "/eecom-teamportal/sales-rep/customer/document",
      DOCUMENT_UPLOAD: "/eecom-teamportal/sales-rep/customer/{contactId}/document/upload",
      DOCUMENT_DELETE: "/eecom-teamportal/sales-rep/customer/{contactId}/document/{documentId}/delete",
    },
    SYSTEM_CONTEXT: {
      FETCH_SALUTATION: "/salutation",
      FETCH_COUNTRY: "/country",
      FETCH_CURRENCY: "/currency",
      FETCH_LANGUAGE: "/language",
      FETCH_CONTEXT: "/context",
      FETCH_STATE: "/eecom-teamportal/sales-rep/customer/country/{countryId}/state",
      CREATE_TAG: "/eecom-teamportal/sales-rep/customer/tag/create",
      UPDATE_TAG: "/eecom-teamportal/sales-rep/customer/tag/update",
      GET_TAG: "/eecom-teamportal/sales-rep/customer/tag",
      DELETE_TAG: "/eecom-teamportal/sales-rep/customer/{id}/tag/{tagId}/delete",
      GET_SALES_CHANNEL: "/eecom-teamportal/sales-rep/customer/salesChannel",
      CREATE_VISIBILITY: "/eecom-teamportal/assortment/{id}/visibility/{visibilityId}/create",
      DELETE_VISIBILITY: "/eecom-teamportal/assortment/visibility/{id}/delete",
      SELECTED_VISIBILITY: "/eecom-teamportal/assortment/visibility/selected",
      UNSELECTED_VISIBILITY: "/eecom-teamportal/assortment/visibility/unselected",
      EMAIL_VALIDATION: "/eecom-teamportal/sales-rep/email-validation",
    },
    ORDER_CATALOG: {
      FETCH_PRODUCTS: "/eecom-teamportal/product",
      FETCH_CUSTOMIZATIONS: "/eecom-teamportal/customization-listing",
      FETCH_FILTER: "/eecom-teamportal/product",
    },
    ASSORTMENT: {
      UPSERT_ASSORTMENT: "/eecom-teamportal/assortment/{customerId}/save",
      FETCH_ASSORTMENTS: "/eecom-teamportal/assortment/{customerId}",
      FETCH_ASSORTMENT_TEAM: "/eecom-tp-assortment-team",
      CREATE_ASSORTMENT_TEAM: "/eecom-tp-assortment-team/create",
      UPDATE_ASSORTMENT_TEAM: "/eecom-tp-assortment-team/update",
      DELETE_ASSORTMENT_TEAM: "/eecom-tp-assortment/{id}/tags/{teamId}/delete",
      UPLOAD_MEDIA: "/eecom-teamportal/sales-rep/assortment/media/upload",
      DELETE_MEDIA: "/eecom-teamportal/sales-rep/assortment/media/{id}/delete",
      DELETE_ALL_ASSORTMENT: "/teamportal/assortment/deleteAll",
      DELETE_ASSORTMENT_BY_ID: "/teamportal/assortment-product/{id}/delete",
      CLONE_ASSORTMENT: "/eecom/clone/{entity}/{id}",
      SET_COVER_MEDIA: "/eecom-teamportal/sales-rep/assortment/setCover/{id}",
      UNSET_COVER_MEDIA: "/eecom-teamportal/sales-rep/assortment/{id}/unSetCover",
      FETCH_ASSORTMENT_PICO_CONFIGURATION_URL: "/eecom-teamportal/assortment/{id}/pico/{productId}/configuration",
      ASSORTMENT_DETAILS_BY_CUSTOMER_ID: "/eecom-teamportal/customer/assortment/detail/{customerId}",
      ASSORTMENT_PRODUCT_DETAILS_BY_ASSORTMENT_ID: "/eecom-teamportal/customer/assortment/product/detail/{assortmentId}",
    },
    OFFER: {
      FETCH_OFFERS: "/eecom-teamportal/offer/{customerId}",
      FETCH_CUSTOMER_OFFERS: "/eecom-teamportal/customer/offer/{customerId}",
      FETCH_CUSTOMER_OFFERS_DETAILS: "/eecom-teamportal/customer/offer/{customerId}/{id}/offer-products",
      FETCH_OFFER: "/eecom-teamportal/offer/{customerId}/{id}",
      UPSERT_OFFERS: "/eecom-teamportal/offer/{customerId}/upsert",
      UPDATE_OFFERS: "/eecom-teamportal/offer/{customerId}/{id}/update",
      DELETE_OFFERS: "/eecom-teamportal/offer/{customerId}/{id}/delete",
      FETCH_OFFER_ASSORTMENT_PRODUCTS: "/eecom-teamportal/offer/{customerId}/{id}/assortment-products",
      CREATE_PDF: "/eecom-teamportal/offer/{customerId}/createPdf",
    },
    TEAM_ORDER: {
      ADD_TO_CART_OLD: "/eecom-teamportal-products/add-to-cart",
      ADD_TO_CART: "/checkout/cart/line-item",
      CHECKOUT_CART: "/checkout/cart",
      REMOVE_FROM_CART: "/checkout/cart/line-item",
      SET_CONFIGURATION: "/teamportal/assortment/set-configuration/{assortmentProductId}",
      SET_ORDER_REFERENCE: "/checkout/cart/order-reference-add",
    },
    ORDER_STATE:{
      OFFER_ASSORTMENT_PRODUCT_CONFIG:"/eecom-teamportal/offer-assortment-product-config"
    },
    PDF_CATALOGUE: {
      UPSERT: "/eecom-teamportal/pdf-catalogue/{customerId}/save",
      DELETE: "/eecom-teamportal/pdf-catalogue/{customerId}/delete",
      UPDATE: "/eecom-teamportal/pdf-catalogue/{customerId}/update",
      FETCH: "/eecom-teamportal/pdf-catalogue/{customerId}",
      FETCH_PRODUCT: "/eecom-teamportal/pdf-catalogue/{id}",
      GENERATE_PDF: "/eecom-teamportal/pdf-catalogue/{id}/generate-pdf",
    },
    PDF_CATALOGUE_PRODUCT: {
      DELETE: "/eecom-teamportal/pdf-catalogue-product/{catalogueId}/{assortmentId}/delete",
    },
    BASKET: {
      DOWNLOAD_PDF: "/eecom-teamportal/basket/{mediaId}/download",
    }
  },
  ROUTE: {
    PATH: {
      LOGIN: "/login",
      DASHBOARD_CUSTOMER: "/customer",
      DASHBOARD_SALES_FORCE: "/sales-force",
      MY_ACCOUNT: "/my-account",
      TEAM_ORDER: "/customer/team-order",
      OFFER: "/customer/offer",
      ORDERS: "/customer/orders",
      ORDER_DETAILS: "/customer/orders/:orderId",
      CONTRACTS_DOCUMENTS: "/customer/documents",
      MEDIA: "/customer/media",
      CHECKOUT: "/customer/checkout",
      CAMPAGIN: "/sales-force/campaign",
      SALES_FORCE_CUSTOMER_DATA_INDEX: "/sales-force/customer-data",
      SALES_FORCE_CUSTOMER_DATA_CREATE: "/sales-force/customer-data/create",
      SALES_FORCE_CUSTOMER_DATA: "/sales-force/customer-data/:contactId",
      SALES_FORCE_CUSTOMER_DATA_BASE: "/sales-force/customer-data/:contactId/base",
      SALES_FORCE_CUSTOMER_DATA_ADDRESSES: "/sales-force/customer-data/:contactId/addresses",
      SALES_FORCE_CUSTOMER_DATA_ORDER: "/sales-force/customer-data/:contactId/order",
      SALES_FORCE_CUSTOMER_DATA_ORDER_DETAIL: "/sales-force/customer-data/:contactId/order/detail/:orderId/base",
      SALES_FORCE_CUSTOMER_DATA_DOCUMENTS: "/sales-force/customer-data/:contactId/documents",
      SALES_FORCE_ORDER_CATALOGUE: "/sales-force/order-catalogue/:contactId",
      SALES_FORCE_PRODUCT_RANGE: "/sales-force/assortment/:contactId",
      SALES_FORCE_PRODUCT_RANGE_DETAILS: "/sales-force/assortment/:contactId/team-collection/:productRangeId/base",
      SALES_FORCE_OFFER_OVERVIEW: "/sales-force/offers/:contactId",
      SALES_FORCE_OFFER_DETAIL: "/sales-force/offers/:contactId/:offerId/detail",
      SALES_FORCE_PDF_CATALOGUE: "/sales-force/pdf-catalogue/:contactId",
      SALES_FORCE_PDF_CATALOGUE_DETAILS: "/sales-force/pdf-catalogue/:contactId/detail/:catalogueId",
      PASSWORD_RECOVERY: "/login/recovery",
      PASSWORD_RECOVERY_CREATE_NEW: "/login/recovery/create-new",
    },
    NAME: {
      LOGIN: "login",
      DASHBOARD_CUSTOMER: "customerDashboard",
      DASHBOARD_SALES_FORCE: "salesForceCustomerOverview",
      MY_ACCOUNT: "myAccount",
      TEAM_ORDER: "teamOrder",
      OFFER: 'offer',
      ORDERS: "orders",
      ORDER_DETAILS: "orderDetails",
      CONTRACTS_DOCUMENTS: "contractsDocuments",
      MEDIA: "media",
      CHECKOUT: "checkout",
      CAMPAGIN: "campaign",
      SALES_FORCE_CUSTOMER_DATA_INDEX: "customerDataIndex",
      SALES_FORCE_CUSTOMER_DATA_CREATE: "customerDataCreate",
      SALES_FORCE_CUSTOMER_DATA: "customerData",
      SALES_FORCE_CUSTOMER_DATA_BASE: "customerDataBase",
      SALES_FORCE_CUSTOMER_DATA_EDIT: "customerDataEdit",
      SALES_FORCE_CUSTOMER_DATA_ADDRESSES: "customerDataAddresses",
      SALES_FORCE_CUSTOMER_DATA_ORDER: "customerDataOrder",
      SALES_FORCE_CUSTOMER_DATA_ORDER_DETAIL: "customerDataOrderDetail",
      SALES_FORCE_CUSTOMER_DATA_DOCUMENTS: "customerDataDocuments",
      SALES_FORCE_ORDER_CATALOGUE: "orderCatalogue",
      SALES_FORCE_PRODUCT_RANGE: "productRange",
      SALES_FORCE_PRODUCT_RANGE_DETAILS: "productRangeDetails",
      SALES_FORCE_OFFER_OVERVIEW: "offerOverview",
      SALES_FORCE_OFFER_DETAIL: "offerDetail",
      SALES_FORCE_PDF_CATALOGUE: "pdfCatalogue",
      SALES_FORCE_PDF_CATALOGUE_DETAILS: "pdfCatalogueDetails",
      PASSWORD_RECOVERY: "passwordRecovery",
      PASSWORD_RECOVERY_CREATE_NEW: "passwordRecoveryCreateNew",
    },
    TITLE: {
      LOGIN: "Log In",
    },
    DESCRIPTION: {
      LOGIN: "Access the adidas TEAM hub login portal. Create your range, manage your teamwear orders and track shipments. Log in now!",
    },
  },
  HTTP_STATUS: {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    INTERNAL_SERVER_ERROR: 500,
  },
  VUE_LOADER_OPTIONS: {
    isFullPage: true,
    opacity: 0.5,
    loader: "spinner",
    transition: "fade",
    lockScroll: true,
  },
  BODY_PARAMS: {
    TOTAL_COUNT_MODE_DEFAULT: 1,
    ASSOCIATIONS: {
      ASSOCIATIONS_CUSTOMER: {
        language: { associations: { translationCode: [] } },
        group: [],
        defaultBillingAddress: [],
        eecomTpContactPerson: {
          associations: {
            contact: [],
            salesRepresentative: {
              associations: {
                defaultBillingAddress: [],
              },
            },
          },
        },
        eecomTpSalesRepresentative: { associations: { contact: [] } },
      },
      ASSOCIATIONS_ORDER: {
        addresses: { associations: { country: [] } },
        orderCustomer: [],
      },
      ASSOCIATIONS_ORDER_DETAIL: {
        transactions: {
          associations: {
            stateMachineState: [],
            paymentMethod: [],
          },
        },
        deliveries: {
          associations: {
            shippingOrderAddress: { associations: { country: [] } },
            shippingMethod: [],
          },
        },
        language: [],
        currency: [],
        documents: [],
        lineItems: {
          associations: {
            cover: [],
            children: { associations: { children: [] } },
          },
          filter: [{ type: "equals", field: "parentId", value: null }],
        },
      },
      ASSOCIATIONS_CUSTOMER_LIST: {
        contact: [],
      },
      ASSOCIATIONS_DOCUMENT_LIST: {
        media: [],
      },
      ASSOCIATIONS_CUSTOMER_LIST_WITH_ADDRESSES: {
        contact: { associations: { addresses: { associations: { country: [] } } } },
      },
      ASSOCIATIONS_CUSTOMER_LIST_WITH_ADDRESSES_COUNTRY: {
        contact: {
          associations: {
            addresses: { associations: { country: [] } },
            tags: [],
            language: { associations: { translationCode: [] } },
            eecomCustomerTranslation: [],
            salesRepTracking: [],
          },
        },
      },
      ASSOCIATIONS_PRODUCT_RANGE_LISTING: {
        eecomTpAssortmentTags: [],
      },
      ASSOCIATIONS_ASSORTMENT_PRODUCTS: {
        eecomTpAssortmentProducts: {
          associations: {
            product: {
              associations: { cover: [] },
            },
            translations: [],
            eecomTpMandatoryCustomizations: {
              associations: {
                eecomTpCustomization: {
                  associations: {
                    stockProduct: {
                      associations: {
                        children: {
                          associations: {
                            options: [],
                          },
                        },
                        configuratorSettings: [],
                        options: [],
                        prices: [],
                      },
                    },
                  },
                },
              },
            },
            eecomTpOptionalCustomizations: {
              associations: {
                eecomTpCustomization: {
                  associations: {
                    stockProduct: {
                      associations: {
                        children: {
                          associations: {
                            options: [],
                          },
                        },
                        configuratorSettings: [],
                        options: [],
                        prices: [],
                      },
                    },
                  },
                },
              },
            },
            eecomTpAssortmentProductMedia: {
              associations: { media: [] },
            },
            eecomTpAssortmentProductVisibility: [],
            eecomProductCustomization: [],
          },
          sort: [
            {
              field: "createdAt",
              order: "DESC",
              naturalSorting: true,
            }
          ]
        },
        eecomTpAssortmentTags: [],
        translations: [],
      },
      ASSOCIATIONS_ASSORTMENT_PRODUCTS_BY_CUSTOMER_ID: {
        eecomTpAssortmentProducts: {
          associations: {
            product: {
              associations: {
                cover: {
                  associations: {
                    media: {},
                  },
                },
                children: {
                  associations: {
                    options: {},
                  },
                },
                configuratorSettings: {},
                options: {},
              },
            },
            eecomTpMandatoryCustomizations: {
              associations: {
                eecomTpCustomization: {
                  associations: {
                    stockProduct: {
                      associations: {
                        children: {
                          associations: {
                            options: [],
                          },
                        },
                        configuratorSettings: [],
                        eecomTpCustomization: [],
                        options: [],
                        prices: [],
                      },
                    },
                  },
                },
              },
            },
            eecomTpOptionalCustomizations: {
              associations: {
                eecomTpCustomization: {
                  associations: {
                    stockProduct: {
                      associations: {
                        children: {
                          associations: {
                            options: [],
                          },
                        },
                        configuratorSettings: [],
                        eecomTpCustomization: [],
                        options: [],
                        prices: [],
                      },
                    },
                  },
                },
              },
            },
            eecomTpAssortmentProductMedia: {
              associations: { media: [] },
            },
            eecomTpAssortmentProductVisibility: [],
            eecomTpAssortmentConfiguration: [],
          },
        },
        eecomTpAssortmentTags: [],
      },
      ASSOCIATIONS_ASSORTMENT_PRODUCTS_DETAIL_BY_CUSTOMER_ID: {
        product: {
          associations: {
            cover: {
              associations: {
                media: {},
              },
            },
            children: {
              associations: {
                eecomTpProduct: {},
                options: {},
                deliveryTime: {},
                deliveryTimes: {
                  associations: {
                    deliveryTime: {},
                  },
                },
              },
            },
            configuratorSettings: {},
            options: {},
            deliveryTimes: {},
          },
        },
        eecomTpMandatoryCustomizations: {
          associations: {
            eecomTpCustomization: {
              associations: {
                stockProduct: {
                  associations: {
                    children: {
                      associations: {
                        options: [],
                      },
                    },
                    configuratorSettings: [],
                    eecomTpCustomization: [],
                    options: [],
                    prices: [],
                  },
                },
              },
            },
          },
        },
        eecomTpOptionalCustomizations: {
          associations: {
            eecomTpCustomization: {
              associations: {
                stockProduct: {
                  associations: {
                    children: {
                      associations: {
                        options: [],
                      },
                    },
                    configuratorSettings: [],
                    eecomTpCustomization: [],
                    options: [],
                    prices: [],
                  },
                },
              },
            },
          },
        },
        eecomTpAssortmentProductMedia: {
          associations: { media: [] },
        },
        eecomTpAssortmentProductVisibility: [],
        eecomTpAssortmentConfiguration: [],
        eecomProductCustomization: [],
      },
      ASSOCIATIONS_OFFER_LISTING: {},
      ASSOCIATIONS_OFFER_PRODUCTS_DETAIL_BY_CUSTOMER_ID: {
        eecomTpOfferAssortmentProducts: {
          associations: {
            eecomTpOfferAssortmentProductConfigs: {
              associations: {
                eecomTpOfferMandatoryConfig: [],
                eecomTpOfferOptionalConfig: []
              }
            },
            eecomTpOfferMandatoryOptions:{
              associations: {
                eecomTpCustomization: {
                  associations: {
                    stockProduct: {
                      associations: {
                        children: {
                          associations: {
                            options: [],
                          },
                        },
                        configuratorSettings: [],
                        options: [],
                        prices: [],
                      }
                    },
                  },
                }
              }
            },
            eecomTpOfferOptionalOptions:{
              associations: {
                eecomTpCustomization: {
                  associations: {
                    stockProduct: {
                      associations: {
                        children: {
                          associations: {
                            options: [],
                          },
                        },
                        configuratorSettings: [],
                        options: [],
                        prices: [],
                      }
                    },
                  },
                },
                eecomTpOfferOptionalConfig: []
              }
            },
            eecomTpAssortmentProduct: {
              associations: {
              eecomTpAssortmentConfiguration: [],
              eecomTpAssortmentProductVisibility: [],
              eecomTpMandatoryCustomizations:{
                  associations: {
                    eecomTpCustomization: {
                      associations: {
                        stockProduct:[],
                      },
                    }
                  }
              },
                eecomTpOptionalCustomizations:{
                  associations: {
                    eecomTpCustomization: {
                      associations: {
                        stockProduct:[],
                      },
                    }
                  }
                },
                product: {
                associations: {
                  configuratorSettings:[],
                  children: {
                    associations: {
                      eecomTpProduct: {},
                      options: {},
                    },
                  },
                  cover: {
                    associations: {
                      media: {},
                    },
                  },
                },
              },
              eecomTpAssortmentProductMedia: {
                associations: {media: []},
              },
            },
            },
          }
        }
      },
      ASSOCIATIONS_PDF_CATALOGUE_PRODUCT: {
        assortmentProducts: {
          position: {},
          associations: {
            product: {
              associations: {
                cover: {
                  associations: {
                    media: {},
                  },
                },
                children: {
                  associations: {
                    options: {},
                  },
                },
                configuratorSettings: {},
                options: {},
              },
            },
            eecomTpMandatoryCustomizations: {
              associations: {
                eecomTpCustomization: {
                  associations: {
                    stockProduct: {
                      associations: {
                        children: {
                          associations: {
                            options: [],
                          },
                        },
                        configuratorSettings: [],
                        eecomTpCustomization: [],
                        options: [],
                        prices: [],
                      },
                    },
                  },
                },
              },
            },
            eecomTpOptionalCustomizations: {
              associations: {
                eecomTpCustomization: {
                  associations: {
                    stockProduct: {
                      associations: {
                        children: {
                          associations: {
                            options: [],
                          },
                        },
                        configuratorSettings: [],
                        eecomTpCustomization: [],
                        options: [],
                        prices: [],
                      },
                    },
                  },
                },
              },
            },
            eecomTpAssortmentProductMedia: {
              associations: { media: [] },
            },
            eecomTpAssortmentProductVisibility: [],
            eecomTpAssortmentConfiguration: [],
          },
        },
      },

    },
    AGGREGATION: {
      AGGREGATION_ORDER: [
        {
          name: "count-order",
          type: "count",
          field: "id",
        },
      ],
      AGGREGATION_PRODUCT_RANGE: [
        {
          name: "count-range",
          type: "count",
          field: "id",
        },
      ],
      AGGREGATION_PRODUCT_RANGE_PRODUCT: [
        {
          name: "count-range-product",
          type: "count",
          field: "id",
        },
      ],
      AGGREGATION_OFFER_RANGE_OFFER: [
        {
          name: "countOffer",
          type: "count",
          field: "eecomTpOfferAssortmentProducts.id",
        },
      ],
      AGGREGATION_OFFER: [
        {
          name: "count-offer",
          type: "count",
          field: "id",
        },
      ],
      AGGREGATION_ADDRESS: [
        {
          name: "count-address",
          type: "count",
          field: "id",
        },
      ],
      AGGREGATION_CUSTOMER_LIST: [
        {
          name: "count-salesforce",
          type: "count",
          field: "contactId",
        },
      ],
      AGGREGATION_DOCUMENT_LIST: [
        {
          name: "count-document",
          type: "count",
          field: "id",
        },
      ],
      AGGREGATION_PDF_CATALOGUE: [
        {
          name: "count-range",
          type: "count",
          field: "id",
        },
      ],
      AGGREGATION_PDF_CATALOGUE_PRODUCT: [
        {
          name: "count-range-product",
          type: "count",
          field: "id",
        },
      ],
    },
    SORTBY: {
      SORTBY_PDF_CATALOGUE_PRODUCT: [
        {
          text: "eecomTpPdfCatalogueProducts.position",
          field: "eecomTpPdfCatalogueProducts.position",
          order: "ASC",
          naturalSorting: true,
        },
      ],
    },
    FILTERS: {
      FILTER_CUSTOMER_LIST_BY_ID: {
        type: "equals",
        field: "contactId",
        value: "contactId",
      },

      FILTER_ADDRESS_LIST_BY_CUSTOMER_ID: {
        type: "equals",
        field: "customerId",
        value: "customerId",
      },
      FILTER_ORDER_LINE_ITEM_PARENT: {
        type: "equals",
        field: "lineItems.parentId",
        value: null,
      },
      FILTER_CUSTOMER_LIST_BY_SALES_FORCE_ID: {
        type: "equals",
        field: "salesRepresentativeId",
        value: "salesRepresentativeId",
      },
      FILTER_ORDER_DETAIL_BY_ORDER_ID: {
        type: "equals",
        field: "id",
        value: "id",
      },
      FILTER_DOCUMENT_LIST_BY_CUSTOMER_ID: {
        type: "equals",
        field: "customerId",
        value: "customerId",
      },
    },
    SEARCH_QUERY: {
      TYPE: ["equals", "contains"],
      SEARCH_QUERY_ORDER: [
        "orderNumber",
        "orderDateTime",
        "addresses.street",
        "addresses.zipcode",
        // "addresses.countryState",
        // "addresses.country",
      ],
      SEARCH_QUERY_PRODUCT_RANGE: ["name"],
      SEARCH_QUERY_OFFER: ["name"],
      SEARCH_QUERY_ADDRESS: ["lastName", "firstName", "company", "street", "zipcode", "city"],
      SEARCH_QUERY_CUSTOMER_LIST: ["contact.customFields.eecom_tp_customer_club_name", "contact.firstName", "contact.lastName", "contact.customerNumber"],
      SEARCH_QUERY_ORDER_CATALOGUE: ["manufacturerNumber", "productNumber"],
      SEARCH_QUERY_ORDER_CATALOGUE_NAME: ["name"],
      SEARCH_QUERY_CUSTOMER_DOCUMENT_LIST: ["name"],
      SEARCH_QUERY_TEAMORDER_LIST: ["name", 'product.name', 'product.productNumber', 'product.manufacturerNumber'],
      SEARCH_QUERY_PRODUCT_RANGE_DETAIL_FIELDS: [
        "product.name",
        "product.productNumber",
        "product.manufacturerNumber"
      ]
    },
    ENTITY: {
      EECOM_TP_ASSORTMENT_PRODUCT: "eecom_tp_assortment_product",
    },
  },
  COMMON: {
    DEFAULT_PAGE_SIZE: 25,
    DEFAULT_TEAM_ORDER_PAGE_SIZE: 10,
    DEFAULT_PAGE: 1,
    DEFAULT_AUTO_SAVE_TIMEOUT_MS: 20000,
    FIELD_NULL: "-",
    FIELD_FALSE: false,
    DEFAULT_COUNTRY_ISO: "DE",
    LIMITED_STOCK: 25,
    DAYS_THRESHOLD: 10,
    TABLE_ACTION: {
      EDIT: "edit",
      DELETE: "delete",
      VIEW: "view",
      DUPLICATE: "duplicate",
      SET_DEFAULT_DELIVERY_ADDRESS: "setDefaultDeliveryAddress",
      SET_DEFAULT_BILLING_ADDRESS: "setDefaultBillingAddress",
    },
    MAX_FILE_UPLOAD: 10,
    MAX_RETRY_COUNT: 5,
    DEFAULT_PRODUCT_SIZE: 1,
    ORDER_CATALOG_FILTERS: {
      MANUFACTURER: "manufacturer",
    },
    LANGUAGE_TRANSLATION_CODE: {
      ENGLISH: "en-GB",
      GERMAN: "de-DE",
    },
    MAX_DISCOUNT_PERCENTAGE: 100,
    MAX_ALLOWED_DISCOUNT_PERCENTAGE: 50,
    MIN_DISCOUNT_PERCENTAGE: 0,
    MIN_ASSORTMENT_PRODUCT_PRICE: 0,
    EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    JOIN_STRING: {
      END_SPACE_COMMA: ", ",
      ADJACENT_SPACE_PLUS: " + ",
    },
    FRACTION_DIGITS: 2,
    SIZE_ORDERS: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    LINE_ITEM: {
      STACKABLE: true,
      QUANTITY: 1,
      REMOVABLE: true,
      TYPE: "eecom-teamportal-product",
      PRODUCT_TYPE: "product",
    },
    CONFIGURATION_FINISHED_FOR_DEVELOPMENT: false,
    CONFIGURATION_FINISHED_FOR_PROD: true,
  },
  FORM: {
    FORM_CUSTOMER: {
      FIRSTNAME: "firstName",
      LASTNAME: "lastName",
      SALUTATION: "salutation",
      TITLE: "title",
      CLUB_COMPANY: "clubCompany",
      EMAIL: "email",
      MOBILE_PHONE: "mobilePhone",
      ALTERNATIVE_PHONE_NUMBER: "alternativePhoneNumber",
      DATE_OF_BIRTH: "birthdayDay",
      LANGUAGE: "language",
      CLUB_LANGUAGE: "clubLanguage",
      TAGS: "tags",
      CLUB_ACCOUNT_NAME: "clubAccountName",
      CUSTOMER_NUMBER: "customerNumber",
      VAT_REG_NO: "vatRegNo",
      PAYMENT_APPROVAL: "payment_approval",
      PAYMENT_OPTION: "payment_option",
      CLASSIFICATION: "classification",
      CUSTOMER_BANNER:"customerBanner",
      CATEGORY: "category",
      MAIN_CLUB_COLOR: "mainClubColor",
      SECONDARY_CLUB_COLOR: "secondClubColor",
      CLUB_LOGO: "clubLogo",
      TEAM_LOGO: "teamLogo",
      WEBSHOP_TEXT_COLOR: "webshopTextColor",
      WEBSHOP_PRIMARY_COLOR: "webshopPrimaryColor",
      WEBSHOP_SECONDARY_COLOR: "webshopSecondaryColor",
      WEBSHOP_CLUB_DESCRIPTION: "webshopClubDescription",
      CURRENT_MAIN_BRAND: "currentMainBrand",
      REGULAR_DISCOUNT: "regularDiscount",
      HOC_CLUBSHOP_DISCOUNT: "hocClubshopDiscount",
      CURRENT_DISCOUNT: "currentDiscount",
      CURRENT_ACCOUNT: "currentAccount",
      ACCOUNT_OWNER: "accountOwner",
      NEW_PASSWORD: "newPassword",
      CONFIRM_PASSWORD: "confirmPassword",
      SEND_LOGIN_DATA_TO_CUSTOMER_EMAIL: "sendLoginDataToCustomerEmail",
      ACCOUNT_STATUS: "accountStatus",
      ALLOW_CULBSHOP_BANNER_IMAGE: "allowClubshopBannerImage",
      CLUBSHOP_BANNER_DESKTOP_IMG: "clubshopBannerDImg",
      CLUBSHOP_BANNER_MOBILE_IMG: "clubshopBannerMImg",
      CLUBSHOP_OVERLAY_IMG: "clubshopOverlayImg",
      SALES_REP_TRACKING_ID: "salesRepTrackingId",
    },
    FORM_CUSTOMER_ADDRESS: {
      COMPANY: "company",
      DEPARTMENT: "department",
      SALUTATION: "salutation",
      TITLE: "title",
      ADDRESS_TITLE: "addressTitle",
      FIRSTNAME: "addressFirstName",
      LASTNAME: "addressLastName",
      STREET: "street",
      ADDITIONAL_ADDRESS_LINE1: "additionalAddressLine1",
      ADDITIONAL_ADDRESS_LINE2: "additionalAddressLine2",
      ZIPCODE: "zipcode",
      CITY: "city",
      COUNTRY: "country",
      STATE: "state",
      PHONE_NUMBER: "phoneNumber",
    },
    FORM_CUSTOMER_CHANGE_PASSWORD: {
      OLD_PASSWORD: "oldPassword",
      NEW_PASSWORD: "newPassword",
      CONFIRM_PASSWORD: "confirmPassword",
    },
    FORM_ORDER_CATALOGUE: {
      MANDANTORY_CUSTOMIZATIONS: "mandantoryCustomizations",
      OPTIONAL_CUSTOMIZATIONS: "optionalCustomizations",
    },
    FORM_PRODUCT_RANGE: {
      MANDANTORY_CUSTOMIZATION_PRICE: "mandantoryCustomizationPrice",
      OPTIONAL_CUSTOMIZATION_PRICE: "optionalCustomizationPrice",
      SELECTED_COLLECTION: "selected_collection",
      RANGE_NAME: "rangeName",
      RANGE_STATUS: "status",
      SELECT_ALL: "productRangeSelectAll",
      SELECT: "productRangeSelect",
      VISIBILITY: "visibility",
      PRODUCT_VISIBILITY: "productVisibility",
      DISCOUNT_PRICE: "discountPrice",
      INPUT_PRICE: "inputPrice",
      PERCENTAGE: "percentage",
      CONFIGURATION_COMPLETE: "configrationComplete",
      UPLOAD_MEDIA: "uploadMedia",
      UPLOAD_FROM_URL: "uploadFromURL",
      FILTER_CONFIGURATION: "filterConfiguration",
      FILTER_SALESCHANNELS: "filterSalesChannels"
    },
    FORM_CUSTOMER_TEAMORDER: {
      IS_USER_CONSENT: "isUserConsent",
    },
    FORM_OFFER: {
      MANDANTORY_CUSTOMIZATION_PRICE: "mandantoryCustomizationPrice",
      OPTIONAL_CUSTOMIZATION_PRICE: "optionalCustomizationPrice",
      SELECTED_COLLECTION: "selected_collection",
      CREATE_OFFER: "createOffer",
      UPDATE_OFFER: "updateOffer"
    },
    FORM_PDF_CATALOGUE: {
      CREATE_PDF_CATALOGUE: "createPdfCatalogue",
      UPDATE_PDF_CATALOGUE: "updatePdfCatalogue",
      CATALOGUE_STATUS: "status",
      CATALOGUE_CLUBSHOP_DESCRIPTION: "pdfClubshopDescription",
      CATALOGUE_PDF_TYPE_PRICE: "withPricePdf",
    },
  },
  ERROR: {
    ERROR_CODE: {
      UNAUTHORIZED: "401",
      FORBIDDEN: "403",
    },
    CODE: {
      CHECKOUT__CUSTOMER_NOT_LOGGED_IN: "CHECKOUT__CUSTOMER_NOT_LOGGED_IN",
      CUSTOMER_PASSWORD_NOT_CORRECT: "VIOLATION::CUSTOMER_PASSWORD_NOT_CORRECT",
    },
  },
  CUSTOMER_ACCOUNT: {
    TYPE: {
      BUSINESS: "business",
    },
  },
  MPNS: [
    "HA3541",
    "GS0177",
    "HA3542",
    "HA3543",
    "HA3539",
    "HA3506",
    "HA0599",
    "HA3505",
    "HA0600",
    "HA3504",
    "HE2948",
    "HA3511",
    "HA3508",
    "HE5661",
    "H21254",
    "HE3057",
    "HD4726",
    "HA6286",
    "HE3058",
    "H21258",
    "HE3061",
    "HD4723",
    "HA6289",
    "HD4725",
    "HE3062",
    "HA6288",
    "HD4728",
    "H44526",
    "H44527",
    "H44528",
    "H44529",
    "HF0126",
    "HF0116",
    "HC2633",
    "HF0131",
    "HB0572",
    "HF0129",
    "HI2124",
    "HI2125",
    "H57572",
    "HG3947",
    "HC5075",
    "H59848",
    "H59849",
    "H57571",
    "HC5074",
    "HH9995",
    "HH9998",
    "HH9999",
    "HI0000",
    "HI0001",
    "HI0002",
    "HE5661",
    "H21254",
    "HA6285",
    "HE3059",
    "HA6291",
    "HA6286",
    "HD2275",
    "HE3060",
    "HD4724",
    "HD4725",
    "HE3062",
    "HD4730",
    "HA6288",
    "HD4728",
    "HR2623",
    "HT6550",
    "HA3513",
    "HA3507",
    "HA3512",
    "HA3514",
    "HA3515",
    "HA3517",
    "HA3518",
    "HE2947",
    "CD8366",
    "CD8358",
    "CD8390",
    "CD8414",
    "CD8430",
    "CD8438",
    "CE9758",
    "CF1035",
    "CF1036",
    "CF1037",
    "CF1038",
    "HG6283",
    "H61736",
    "HC5071",
    "HC5072",
    "HC5073",
    "HC5077",
    "HE1573",
    "HE1574",
    "HE1575",
    "HI2122",
    "HI2123",
    "H61735",
    "H57504",
    "H57505",
    "H57506",
    "HC5051",
    "HC5061",
    "HG6294",
    "HG6295",
    "H57507",
    "DP3238",
    "DP3230",
    "DP3231",
    "DP3232",
    "DP3233",
    "DP3234",
    "DP3235",
    "DP3236",
    "DP3237",
    "DP3240",
    "GK9805",
    "GN5795",
    "GN6944",
    "GN5792",
    "GK9152",
    "GN5790",
    "GN5791",
    "GN5793",
    "GN5721",
    "GK9154",
    "GN5720",
    "GN5722",
    "GN5723",
    "GN5724",
    "GN5725",
    "GN5726",
    "GN5728",
    "GN6726",
    "GN8091",
    "GN8092",
    "GN5758",
    "GK9150",
    "GN5752",
    "GN5753",
    "GN5757",
    "GN5759",
    "GN8087",
    "GK9153",
    "GN5769",
    "GN5770",
    "GN5771",
    "GN5772",
    "GN5773",
    "GN5774",
    "GN5775",
    "GN5776",
    "GN5777",
    "GN6720",
    "GN8083",
    "GN8084",
    "GN5783",
    "GK9149",
    "GN5779",
    "GN5780",
    "GN5782",
    "GN5784",
    "GN8086",
    "GN7624",
    "GH7321",
    "GN5845",
    "GN5847",
    "GN7625",
    "GV1377",
    "GV1378",
    "GV1380",
    "GV1381",
    "H35642",
    "H35643",
    "H35644",
    "HD2306",
    "HA6232",
    "H44107",
    "H44105",
    "H44106",
    "H44108",
    "HD2320",
    "HG6307",
    "HA6245",
    "H21276",
    "HA6238",
    "HA6246",
    "HD2272",
    "HA6240",
    "H21288",
    "HA6267",
    "H21261",
    "HA6259",
    "HD2316",
    "HA6250",
    "HA6249",
    "HA6252",
    "HB0005",
    "HD2286",
    "HB0003",
    "HA6241",
    "HC0448",
    "HC0449",
    "HC0450",
    "HC0451",
    "HC0452",
    "GK9548",
    "GP6435",
    "GP6436",
    "GP6437",
    "GP6438",
    "GK9556",
    "GP6427",
    "GP6428",
    "GP6429",
    "GP6430",
    "GP6445",
    "GP6446",
    "GP6447",
    "GP6448",
    "GT8795",
    "GT6634",
    "GT6635",
    "GT6637",
    "GT6638",
    "GT6639",
    "GT6640",
    "GT6641",
    "GK9546",
    "GP6462",
    "GP6463",
    "GP6464",
    "GP6465",
    "GK9545",
    "GK9562",
    "GP6472",
    "GP6475",
    "GN5677",
    "GN5675",
    "GK9088",
    "GN7504",
    "GN7508",
    "GN7507",
    "GN7503",
    "GN5674",
    "GN7505",
    "GN7506",
    "GN5676",
  ],
  PLAYER_CONFIGURATIONS: {
    LETTERS_WITH_ACUTE: [
      "Á",
      "á",
      "B́",
      "b́",
      "Ć",
      "ć",
      "D́",
      "d́",
      "É",
      "é",
      "F́",
      "f́",
      "Ǵ",
      "ǵ",
      "H́",
      "h́",
      "Í",
      "í",
      "J́",
      "ȷ́",
      "Ḱ",
      "ḱ",
      "Ĺ",
      "ĺ",
      "Ḿ",
      "ḿ",
      "Ń",
      "ń",
      "Ó",
      "ó",
      "Ṕ",
      "ṕ",
      "Q́",
      "q́",
      "Ŕ",
      "ŕ",
      "Ś",
      "ś",
      "T́",
      "t́",
      "Ú",
      "ú",
      "V́",
      "v́",
      "Ẃ",
      "ẃ",
      "X́",
      "x́",
      "Ý",
      "ý",
      "Ź",
      "ź",
    ],
    LETTERS_WITH_CIRCUMFLEX: [
      "Â",
      "â",
      "B̂",
      "b̂",
      "Ĉ",
      "ĉ",
      "D̂",
      "d̂",
      "Ê",
      "ê",
      "Ĝ",
      "ĝ",
      "Ĥ",
      "ĥ",
      "Î",
      "î",
      "Ĵ",
      "ĵ",
      "K̂",
      "k̂",
      "L̂",
      "l̂",
      "M̂",
      "m̂",
      "N̂",
      "n̂",
      "Ô",
      "ô",
      "R̂",
      "r̂",
      "Ŝ",
      "ŝ",
      "T̂",
      "t̂",
      "Û",
      "û",
      "V̂",
      "v̂",
      "Ŵ",
      "ŵ",
      "X̂",
      "x̂",
      "Ŷ",
      "ŷ",
      "Ẑ",
      "ẑ",
    ],
    LETTERS_WITH_GRAVE: ["À", "à", "È", "è", "H̀", "h̀", "Ì", "ì", "K̀", "k̀", "M̀", "m̀", "Ǹ", "ǹ", "Ò", "ò", "R̀", "r̀", "S̀", "s̀", "T̀", "t̀", "Ù", "ù", "V̀", "v̀", "Ẁ", "ẁ", "X̀", "x̀", "Ỳ", "ỳ", "Z̀", "z̀"],
    SMALL_CAPS_LETTERS: [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
    ALLOWED_SPECIAL_CHARACTERS: [" ", "-", ".", "`", "´", "ˆ"],
    GERMAN_UMLAUTS: ["Ä", "ä", "Ö", "ö", "Ü", "ü", "ß"],
    FRENCH_TREMA_CEDILLA: ["Ë", "ë", "Ï", "ï", "Ü", "ü", "Ç", "ç", "Œ", "œ", "Æ", "æ"],
    REGEX: {
      PLAYER_NAME: "/[A-Za-z]{20}/",
      NUMBER: "/[0-9]{1,2}/",
      INITIALS: "/[A-Za-z]{2}/",
      ONLY_DIGITS: /(\d+)/,
      ENGLISH_GERMAN_CHARACTERS: /^[a-zA-ZäöüÄÖÜß]+$/,
      // UNICODE_CHARACTERS_OLD: /^[\p{L}\p{M}\s'-.]+/u,
      // UNICODE_CHARACTERS_OLD1: /^[\p{L}\p{M}\s'-]*$/u,
      // UNICODE_CHARACTERS_OLD2: /^[\p{L}\p{M}\s\-’]*$/u,
      // UNICODE_CHARACTERS: /^(?![\s\S]*[ÿãµƒ])[\p{L}\s\-’.]*$/u
      // UNICODE_CHARACTERS: /^[^\u00F0\u0192\u02CD\u00F8\u0153\u00DF\u00FE\u02C7\u02C0](?![\s\S]*[µƒþøßðˀˇœ])[\p{L}À-ÖØ-öø-ÿ .'-]*(?:[`'^´][\p{L}À-ÖØ-öø-ÿ]*)*$/u
      // UNICODE_CHARACTERS: /^(?![\u00F0\u0192\u02CD\u00F8\u0153\u00DF\u00FE\u02C7\u02C0\u00A8\u02DC\u00E4-\u00EB\u00F1\u00F5-\u00F6\u00FC])(?![\s\S]*[µƒþøßðˀˇœ˜])[\p{L}À-ÖØ-öø-ÿ `´^.'-]*$/u
      // UNICODE_CHARACTERS: /^(?![\u00F0\u0192\u02CD\u00F8\u0153\u00DF\u00FE\u02C7\u02C0\u00A8\u02DC])[a-zA-ZÀ-ÖØ-öø-ÿ `´^.'-]*(?<!\u00E3|\u00F5)[a-zA-ZÀ-ÖØ-öø-ÿ `´^.'-]*$/u
      // UNICODE_CHARACTERS: /^[\w\s.'`\-^´ˆ]*$/u
      // UNICODE_CHARACTERS: /^[\p{L}\s.'`\-^´ˆ]*$/u
      // UNICODE_CHARACTERS: /^(?![ðƒˍøœßþˇˀ])[a-zA-ZÀ-ÖØ-öø-ÿ `´^.'-]*(?<![\u0303\u0308])[a-zA-ZÀ-ÖØ-öø-ÿ `´^.'-]*$/u
      UNICODE_CHARACTERS: /^(?![\s\S]*[ðƒˍøœßþˇˀ])[\p{L}\s.'`\-^´ˆ]*$/u,
    },
    TYPE: {
      USER_INPUT: "user-input",
      PREFILLED_INPUT: "prefilled",
    },
    CUSTOMISATION: {
      TYPE: {
        MANDATORY: "mandatory",
        OPTIONAL: "optional",
      },
    },
  },
  LOGIN_TYPE: {
    SALES_REP_CUSTOMER: "sales-rep-customer",
    CUSTOMER: "customer",
  },
  INDEX_DB: {
    NAME: {
      OFFER_PLAYER_CONFIGURATION: "offerPlayerConfiguration",
      OFFER_PLAYER_CONFIGURATION_CUSTOMER: "offerPlayerConfigurationCustomer",
      PLAYER_CONFIGURATION: "playerConfiguration",
    },
    VERSION: {
      OFFER_PLAYER_CONFIGURATION: 1,
      OFFER_PLAYER_CONFIGURATION_CUSTOMER: 1,
      PLAYER_CONFIGURATION: 1,
    },
    OBJECT_STORE: {
      NAME: {
        ASSORTMENT_ITEM: "assortmentItemObject",
        OFFER_ASSORTMENT_ITEM: "offerAssortmentItemObject",
        OFFER_ASSORTMENT_ITEM_CUSTOMER: "offerAssortmentItemCustomerObject",
      },
      OPTIONS: {
        DEFAULT: {keyPath: "id", autoIncrement: false},
      }
    }
  },
  DELIVERY_TIMES: {
    AVAILABLE: "available",
    CONTRACT_WAREHOUSE: "contractWarehouse",
  },
}
