import { EecomTpAssortmentProduct } from "@/types/eecomTpAssortmentProduct"
import { _getValue, getOptionPrice, getSumOfTwoNumbersWithFractionDigits, getTotalValue, imageLargeUrl, imageMediumUrl, imageUrl } from "@/helpers/common"
import { CONSTANT } from "@/helpers/constant"
import { Media } from "@/types/media"

export const _calculateFinishesPriceInNumber = (currentAssortmentItemObject: object | undefined, assortmentProduct: EecomTpAssortmentProduct, totalChildrenValue: number) => {
  const eecomTpAssortmentConfiguration = JSON.parse(_getValue(currentAssortmentItemObject, "configuration", "{}").value)
  const lineItems = _getValue(eecomTpAssortmentConfiguration, "items[0].lineItem", {}).value
  let total = 0
  let nonUserInputTotal = 0
  let userInputTotal = 0

  if (totalChildrenValue > 0) {
    const mandatoryCustomizations = assortmentProduct?.eecomTpMandatoryCustomizations ?? []
    const optionalCustomizations = assortmentProduct?.eecomTpOptionalCustomizations ?? []
    const allCustomisations = [...mandatoryCustomizations, ...optionalCustomizations]

    Object.keys(lineItems).forEach((key) => {
      const lineItem = lineItems[key]

      allCustomisations.forEach((customisation) => {
        const optionKey = lineItem.options ? Object.keys(lineItem.options).find((optionKey) => optionKey === customisation.id) : null
        const option = optionKey ? lineItem.options[optionKey] : null

        if (option) {
          const count = customisation.eecomTpCustomization.type !== CONSTANT.PLAYER_CONFIGURATIONS.TYPE.USER_INPUT ? lineItem.quantity : option.values.filter((o: any) => o.value).length
          const price = getOptionPrice(customisation, count)
          if (customisation.eecomTpCustomization.type !== CONSTANT.PLAYER_CONFIGURATIONS.TYPE.USER_INPUT) {
            nonUserInputTotal = getSumOfTwoNumbersWithFractionDigits(nonUserInputTotal, getTotalValue(count, price), CONSTANT.COMMON.FRACTION_DIGITS)
          } else {
            userInputTotal = getSumOfTwoNumbersWithFractionDigits(userInputTotal, getTotalValue(count, price), CONSTANT.COMMON.FRACTION_DIGITS)
          }
        }
      })
    })
    total = getSumOfTwoNumbersWithFractionDigits(nonUserInputTotal, userInputTotal, CONSTANT.COMMON.FRACTION_DIGITS)
  }
  return total
}

export const getAssortmentProductMediaUrl = (assortmentProductMedia: Media) => {
  const media = _getValue(assortmentProductMedia, "media.url", null).value
  if (media) {
    return imageUrl(media)
  }
  return null
}

export const getAssortmentProductMediaMediumUrl = (assortmentProductMedia: Media) => {
  const media = _getValue(assortmentProductMedia, "media.url", null).value
  if (media) {
    return imageMediumUrl(media)
  }
  return null
}

export const getAssortmentProductMediaLargeUrl = (assortmentProductMedia: Media) => {
  const media = _getValue(assortmentProductMedia, "media.url", null).value
  if (media) {
    return imageLargeUrl(media)
  }
  return null
}

export const getAssortmentProductMediaCoverUrl = (assortmentProduct: EecomTpAssortmentProduct) => {
  const media: string = _getValue(assortmentProduct, "product.cover.media.url", null).value
  if (media) {
    return imageUrl(media)
  }
  return null
}

export const getAssortmentProductMediaCoverMediumUrl = (assortmentProduct: EecomTpAssortmentProduct) => {
  const media: string = _getValue(assortmentProduct, "product.cover.media.url", null).value
  if (media) {
    return imageMediumUrl(media)
  }
  return null
}

export const getAssortmentProductMediaCoverLargeUrl = (assortmentProduct: EecomTpAssortmentProduct) => {
  const media: string = _getValue(assortmentProduct, "product.cover.media.url", null).value
  if (media) {
    return imageLargeUrl(media)
  }
  return null
}
