import { CONSTANT } from "@/helpers/constant"

const guest = ({ from, contextToken, authedUser }: MiddlewareContext) => {
  const isAuthenticated = !!(contextToken && authedUser)
  const isSalesRep = authedUser?.customFields?.eecom_tp_is_sales_rep || false

  if (!isAuthenticated) {
    return true; // Allow access if not authenticated
  }

  const targetPath = isSalesRep 
    ? CONSTANT.ROUTE.PATH.DASHBOARD_SALES_FORCE 
    : CONSTANT.ROUTE.PATH.DASHBOARD_CUSTOMER;

  if (!from.path.startsWith(targetPath)) {
    return targetPath;
  }

  return false; // Prevent access if already authenticated and in the right section
}

export default guest
