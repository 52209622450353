/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CONSTANT } from "@/helpers/constant"
import _, { get } from "lodash"
import { router } from "@/router"
import { AssortmentItemObject, CartLineItem, ChildrenValues, Configuration, ProductQuantity } from "@/types/teamOrder"
import { EecomTpAssortmentProduct } from "@/types/eecomTpAssortmentProduct"
import { Product } from "@/types/product"
import { CalculatedPrice } from "@/types/cart/price/calculatedPrice"
import { CalculatedTax } from "@/types/tax/calculatedTax"
import { TaxRule } from "@/types/tax/taxRule"
import { Cart } from "@/types/cart/cart"
import { LineItem } from "@/types/cart/line-item/lineItem"
import { RouteLocationNormalized, RouteRecordName } from "vue-router"
import { WindowWithDataLayer } from "@/definition/window"
import { SearchQueryObject } from "@/types/datatable"
import imageService from "@/helpers/image-service"
import { useI18n } from "vue-i18n"
import { getDefaultLanguage } from "@/internationalisation"
import { Language, Translations } from "@/types/language"
import { IndexedDbService } from "./indexed-db-service"
import dayjs from "dayjs"

interface Price {
  unitPrice: number
  quantity: number
  totalPrice: number
  calculatedTaxes: CalculatedTax[]
  taxRules: TaxRule[]
  referencePrice: number | null
  listPrice: number | null
  apiAlias: string
}

interface PriceProduct {
  price: Price
}

export const getSearchQueryObject = (value: string, type: string, field: string) => {
  return { query: { field, type, value } }
}

declare const window: WindowWithDataLayer

export const getOrdersSearchFilter = (value: string) => {
  const orderSearchQuery = CONSTANT.BODY_PARAMS.SEARCH_QUERY.SEARCH_QUERY_ORDER
  return getSearchQuery(orderSearchQuery, value)
}
export const getProductRangeSearchFilter = (value: string) => {
  const orderSearchQuery = CONSTANT.BODY_PARAMS.SEARCH_QUERY.SEARCH_QUERY_PRODUCT_RANGE
  return getSearchQuery(orderSearchQuery, value)
}
export const getOfferSearchFilter = (value: string) => {
  const orderSearchQuery = CONSTANT.BODY_PARAMS.SEARCH_QUERY.SEARCH_QUERY_OFFER
  return getSearchQuery(orderSearchQuery, value)
}
export const getOrderCatalogueSearchQuery = (value: string) => {
  const addressSearchQuery = CONSTANT.BODY_PARAMS.SEARCH_QUERY.SEARCH_QUERY_ORDER_CATALOGUE
  return getSearchQuery(addressSearchQuery, value)
}
export const getOrderCatalogueNameQuery = (value: string) => {
  const productNameQuery = CONSTANT.BODY_PARAMS.SEARCH_QUERY.SEARCH_QUERY_ORDER_CATALOGUE_NAME
  return getSearchQuery(productNameQuery, value)
}
const getSearchQuery = (searchQueryArray: any[], value: string) => {
  const searchQuery = [] as SearchQueryObject[]
  const searchType = CONSTANT.BODY_PARAMS.SEARCH_QUERY.TYPE
  searchQueryArray.map((field) =>
    searchType.map((type) => {
      searchQuery.push(getSearchQueryObject(value, type, field))
    })
  )
  return searchQuery
}
export const getCustomerListSearchFilter = (value: string) => {
  const customerListSearchQuery = CONSTANT.BODY_PARAMS.SEARCH_QUERY.SEARCH_QUERY_CUSTOMER_LIST
  const customerListSearchType = CONSTANT.BODY_PARAMS.SEARCH_QUERY.TYPE
  const searchQuery = [] as SearchQueryObject[]
  customerListSearchQuery.map((field) =>
    customerListSearchType.map((type) => {
      searchQuery.push(getSearchQueryObject(value, type, field))
    })
  )
  return searchQuery
}
export const getCustomerDocumentListSearchFilter = (value: string) => {
  const customerDocumentListSearchQuery = CONSTANT.BODY_PARAMS.SEARCH_QUERY.SEARCH_QUERY_CUSTOMER_DOCUMENT_LIST
  const customerDocumentListSearchType = CONSTANT.BODY_PARAMS.SEARCH_QUERY.TYPE
  const searchQuery = [] as SearchQueryObject[]
  customerDocumentListSearchQuery.map((field) =>
    customerDocumentListSearchType.map((type) => {
      searchQuery.push(getSearchQueryObject(value, type, field))
    })
  )
  return searchQuery
}
export const getDayMonthYear = (_date: any) => {
  const d = new Date(_date)
  let dateMonth: any = d.getMonth() + 1
  let dateDay: any = d.getDate()
  const dateYear = Number(d.getFullYear())
  if (dateMonth < 10) {
    dateMonth = Number(`0${dateMonth}`)
  }
  if (dateDay < 10) {
    dateDay = Number(`0${dateDay}`)
  }
  return { dateDay, dateMonth, dateYear }
}
export const getDayMonthYearString = (_date: string) => {
  const d = new Date(_date)
  let dateMonth: any = d.getMonth() + 1
  let dateDay: any = d.getDate()
  const dateYear = d.getFullYear()
  if (dateMonth < 10) {
    dateMonth = `0${dateMonth}`
  }
  if (dateDay < 10) {
    dateDay = `0${dateDay}`
  }
  return { dateDay, dateMonth, dateYear }
}
export const getDateFormatted = (_date: string) => {
  const { dateDay, dateMonth, dateYear } = getDayMonthYearString(_date)
  return [dateDay, dateMonth, dateYear].join(".")
}
export const getTimeFormatted = (_date: any) => {
  const d = new Date(_date)
  let dateHours: any = d.getHours()
  let dateMinutes: any = d.getMinutes()
  if (dateHours < 10) {
    dateHours = `0${dateHours}`
  }
  if (dateMinutes < 10) {
    dateMinutes = `0${dateMinutes}`
  }
  return [dateHours, dateMinutes].join(":")
}
export const logoutFromBrowser = async () => {
  localStorage.removeItem("contextToken")
  localStorage.removeItem("customer")
  await router.push(CONSTANT.ROUTE.PATH.LOGIN)
  window.location.reload()
}
export const getAvatarFromCustomerObject = (customerObj: any) => {
  let avatarText = ""
  if (customerObj && Object.keys(customerObj).length > 0) {
    const firstName = _getValue(customerObj, "firstName", "First Name").value
    const lastName = _getValue(customerObj, "lastName", "Last Name").value
    avatarText = [firstName, lastName].map((n) => n[0]).join("")
  }
  return avatarText
}
export const getFullNameWithSalutation = (object: any, salutationList: any) => {
  const salutationObj = salutationList.find((item: any) => item.id === object.salutationId)
  const salutationDisplayName = salutationObj ? salutationObj.displayName : ""
  const firstName = _.get(object, "firstName", "")
  const lastName = _.get(object, "lastName", "")
  return [salutationDisplayName, firstName, lastName].join(" ")
}
export const _getValue = (object: any, path: string, defaultValue: any = null) => {
  try {
    const getResult = _.get(object, path, defaultValue)
    let value = getResult === null ? "" : getResult
    const result = value !== ""
    if (!result) {
      value = defaultValue
    }
    return { value, result }
  } catch {
    return { value: "", result: false }
  }
}

export const _diff = (obj1: any, obj2: any) => {
  const result: any = {}
  if (Object.is(obj1, obj2)) {
    return undefined
  }
  if (!obj2 || typeof obj2 !== "object") {
    return obj2
  }
  if (obj1 && obj2) {
    Object.keys(obj1 || {})
      .concat(Object.keys(obj2 || {}))
      .forEach((key) => {
        if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
          result[key] = obj2[key]
        }
        if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
          const value = _diff(obj1[key], obj2[key])
          if (value !== undefined) {
            result[key] = value
          }
        }
      })
  }
  return result
}

export const getFormattedDateTime = (date: string) => {
  return new Intl.DateTimeFormat("de-DE", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    hour12: false,
    minute: "2-digit",
  }).format(new Date(date))
}
export const isValidRegex = (regex: string) => {
  let isValid = true
  try {
    new RegExp(regex)
  } catch (e) {
    isValid = false
  }
  return isValid
}

export const findPercentageByDiscountedPrice = (price: any, discountedPrice: any) => {
  const discountPercentage = 100 - (discountedPrice / price) * 100
  return discountPercentage % 1 === 0 ? discountPercentage : discountPercentage.toFixed(2)
}
export const getUserInputCustomisation = (item: any) => {
  return item.filter((i: any) => i.eecomTpCustomization.type === CONSTANT.PLAYER_CONFIGURATIONS.TYPE.USER_INPUT || i.eecomTpCustomization.expertMode)
}
export const getPreFilledInputCustomisation = (item: any) => {
  return item.filter((i: any) => i.eecomTpCustomization.type === CONSTANT.PLAYER_CONFIGURATIONS.TYPE.PREFILLED_INPUT)
}

function createUserInputOptionValues(customisations: any, currentChildrenValues: any, configuredLineItems: any, childId: any) {
  const options: any = {}
  customisations.forEach((customisation: any) => {
    options[customisation.id] = {
      type: CONSTANT.PLAYER_CONFIGURATIONS.TYPE.USER_INPUT,
      values: [],
    }

    for (let i = 0; i < currentChildrenValues[childId]; i++) {
      const amount = i && i > 0 ? i + 1 : 1
      let value: any = ""

      const configuredOption = _getValue(configuredLineItems, `${childId}.options.${customisation.id}`, null).value

      if (configuredOption && configuredLineItems[childId].options[customisation.id].values[i]) {
        const isValueExist = configuredLineItems[childId].options[customisation.id].values[i].value
        const isAmountExist = configuredLineItems[childId].options[customisation.id].values[i].amount
        value = isAmountExist === amount && isValueExist ? isValueExist : ""
      } else {
        value = ""
      }
      options[customisation.id].values.push({
        amount,
        value,
      })
    }
  })
  return options
}

function createUserInputOptionValuesV3(customisations: any, currentChildrenValues: any, configuredLineItems: any, childId: any) {
  const options: any = {};

  customisations.forEach((customisation: any) => {
    options[customisation.id] = {
      type: CONSTANT.PLAYER_CONFIGURATIONS.TYPE.USER_INPUT,
      values: [],
    };

    const maxIterations = customisation.hasOwnProperty('optionQty') ? customisation.optionQty : currentChildrenValues[childId];

    for (let i = 0; i < maxIterations; i++) {
      const amount = i + 1;
      let value: any = "";

      const optionValues = configuredLineItems[childId]?.options?.[customisation.id]?.values || [];
      const configuredOption = optionValues[i] ? optionValues[i].value : null;

      if (configuredOption && optionValues?.[i]) {
        const isValueExist = optionValues[i]?.value;
        const isAmountExist = optionValues[i]?.amount;
        value = isAmountExist === amount && isValueExist ? isValueExist : "";
      } else {
        value = "";
      }

      options[customisation.id].values.push({
        amount,
        value,
      });
    }
  });

  return options;
}

function createChildProduct(child: Product, currentChildrenValues: any, configuredLineItems: any, mandatoryUserInputCustomisation: any, optionalUserInputCustomisation: any): Product {
  const childProductsObj = {
    id: child.id,
    type: "product",
    referencedId: child.id,
    stackable: true,
    removable: true,
    quantity: currentChildrenValues[child.id],
    options: {},
  }
  const mandatoryOptions = createUserInputOptionValues(mandatoryUserInputCustomisation, currentChildrenValues, configuredLineItems, child.id)
  const optionalOptions = createUserInputOptionValues(optionalUserInputCustomisation, currentChildrenValues, configuredLineItems, child.id)
  childProductsObj.options = {
    ...mandatoryOptions,
    ...optionalOptions,
  }
  child["playerConfiguration"] = childProductsObj
  return child
}

export function sortChildren(product: Product): Product[] {
  const result: Product[] = []

  if (!product.children) {
    return result
  }
  if (product && product?.configuratorSettings && product?.configuratorSettings.length > 0) {
    for (const configuration of product.configuratorSettings.sort((a: any, b: any) => a.position - b.position)) {
      for (const child of product.children) {
        if (!child.options || (child.options && child.options[0] && child.options[0].id !== configuration.optionId)) {
          continue
        }
        result.push(child)
      }
    }
  }
  return result
}

export const getCustomConfigurationArray = (currentChildrenValues: ChildrenValues, eecomTpAssortmentProduct: EecomTpAssortmentProduct, product: Product | null, assortmentItemObject: any) => {
  const mandatoryUserInputCustomisation = getUserInputCustomisation(_.get(eecomTpAssortmentProduct, "eecomTpMandatoryCustomizations", []))
  const mandatoryPreFilledInputCustomisation = getPreFilledInputCustomisation(_.get(eecomTpAssortmentProduct, "eecomTpMandatoryCustomizations", []))
  const optionalUserInputCustomisation = getUserInputCustomisation(_.get(eecomTpAssortmentProduct, "eecomTpOptionalCustomizations", []))

  const eecomTpAssortmentConfiguration = JSON.parse(_getValue(assortmentItemObject, "configuration", "{}").value)
  // const eecomTpAssortmentConfiguration = _getValue(eecomTpAssortmentProduct,`eecomTpAssortmentConfiguration[${_.get(eecomTpAssortmentProduct,'eecomTpAssortmentConfiguration.length',1) - 1}].configuration`, {}).value;

  const configuredLineItems = _getValue(eecomTpAssortmentConfiguration, `items[0].lineItem`, null).value
  const customConfigurationArray: Product[] = []
  // const productChildren = _getValue(product,`children`, []).value;
  if (product) {
    const productId: string = _getValue(product, `id`, null).value as string
    let productChildren = sortChildren(product)
    if (productChildren && (productChildren.length === 0 || (productChildren.length === 1 && productChildren[0].id === productId))) {
      currentChildrenValues[`${productId}`] = CONSTANT.COMMON.DEFAULT_PRODUCT_SIZE
      product.children = [
        {
          id: productId,
          calculatedCheapestPrice: null,
          calculatedPrices: [],
          calculatedPrice: null,
          parent: null,
          children: [],
          cover: null,
        },
      ]
      productChildren = product.children
    }
    productChildren.forEach((child: Product) => {
      if (currentChildrenValues && Object.keys(currentChildrenValues).length > 0) {
        Object.keys(currentChildrenValues).forEach((key: any) => {
          if (child.id === key && currentChildrenValues[key] >= 0) {
            customConfigurationArray.push(createChildProduct(child, currentChildrenValues, configuredLineItems, mandatoryUserInputCustomisation, optionalUserInputCustomisation))
          }
        })
      }
    })
  }

  if (customConfigurationArray && customConfigurationArray.length > 0) {
    customConfigurationArray.forEach((child: any) => {
      mandatoryPreFilledInputCustomisation.forEach((c: any) => {
        child.playerConfiguration.options[c.id] = {
          type: CONSTANT.PLAYER_CONFIGURATIONS.TYPE.PREFILLED_INPUT,
        }
      })
    })
  }
  return customConfigurationArray
}

export const getCustomConfigurationArrayV2 = (productQuantityValues: ProductQuantity, eecomTpAssortmentProduct: EecomTpAssortmentProduct, product: Product | null, configuredLineItems: any) => {
  const mandatoryUserInputCustomisation = getUserInputCustomisation(_.get(eecomTpAssortmentProduct, "eecomTpMandatoryCustomizations", []))
  const mandatoryPreFilledInputCustomisation = getPreFilledInputCustomisation(_.get(eecomTpAssortmentProduct, "eecomTpMandatoryCustomizations", []))
  const optionalUserInputCustomisation = getUserInputCustomisation(_.get(eecomTpAssortmentProduct, "eecomTpOptionalCustomizations", []))

  const customConfigurationArray: any = []
  if (product) {
    const children = sortChildren(product)
    if (children && children.length > 0) {
      children.forEach((child: Product) => {
        if (productQuantityValues && productQuantityValues[child.id] >= 0) {
          customConfigurationArray.push(createChildProductV2(child, productQuantityValues, configuredLineItems, mandatoryUserInputCustomisation, optionalUserInputCustomisation))
        }
      })
    } else {
      // If product is base product
      if (productQuantityValues && productQuantityValues[product.id] >= 0) {
        customConfigurationArray.push(createChildProductV2(product, productQuantityValues, configuredLineItems, mandatoryUserInputCustomisation, optionalUserInputCustomisation))
      }
    }
  }

  if (customConfigurationArray && customConfigurationArray.length > 0) {
    customConfigurationArray.forEach((child: any) => {
      mandatoryPreFilledInputCustomisation.forEach((c: any) => {
        child.playerConfiguration.options[c.id] = {
          type: CONSTANT.PLAYER_CONFIGURATIONS.TYPE.PREFILLED_INPUT,
        }
      })
    })
  }

  return customConfigurationArray
}

export const createChildProductV2 = (child: Product, productQuantityValues: ProductQuantity, configuredLineItems: any, mandatoryUserInputCustomisation: any, optionalUserInputCustomisation: any) => {
  const childProductsObj = {
    id: child.id,
    type: "product",
    referencedId: child.id,
    stackable: true,
    removable: true,
    quantity: productQuantityValues[child.id],
    options: {},
  }
  const mandatoryOptions = createUserInputOptionValues(mandatoryUserInputCustomisation, productQuantityValues, configuredLineItems, child.id)
  const optionalOptions = createUserInputOptionValues(optionalUserInputCustomisation, productQuantityValues, configuredLineItems, child.id)
  childProductsObj.options = {
    ...mandatoryOptions,
    ...optionalOptions,
  }

  return {
    id: child.id,
    options: child.options,
    playerConfiguration: childProductsObj,
    mandatoryCustomization: mandatoryUserInputCustomisation,
    optionalCustomization: optionalUserInputCustomisation,
  }
}

function getMaxQuantityByChildId(quantities: any, childId: string) {
  let maxQuantity = -Infinity; // Initialize with a very low number

  // Iterate through each key-value pair in quantities object
  Object.keys(quantities).forEach(key => {
    const [optionId, currentChildId] = key.split('_');

    // Check if currentChildId matches the provided childId
    if (currentChildId === childId) {
      const quantity = quantities[key];
      if (quantity > maxQuantity) {
        maxQuantity = quantity;
      }
    }
  });

  return maxQuantity !== -Infinity ? maxQuantity : 0; // Return 0 if no valid maxQuantity found
}

export const createChildProductV3 = (child: Product, optionalOptionQuantityValues: any, productQuantityValues: ProductQuantity, configuredLineItems: any, mandatoryUserInputCustomisation: any, optionalUserInputCustomisation: any) => {
  const childProductsObj = {
    id: child.id,
    type: "product",
    referencedId: child.id,
    stackable: true,
    removable: true,
    quantity: Number(productQuantityValues[child.id]), //getMaxQuantityByChildId(optionalOptionQuantityValues, child.id),
    options: {},
  }
  const mandatoryOptions = createUserInputOptionValuesV3(mandatoryUserInputCustomisation, productQuantityValues, configuredLineItems, child.id)
  const optionalOptions = createUserInputOptionValuesV3(optionalUserInputCustomisation, productQuantityValues, configuredLineItems, child.id)
  childProductsObj.options = {
    ...mandatoryOptions,
    ...optionalOptions,
  }
  return {
    id: child.id,
    options: child.options,
    playerConfiguration: childProductsObj,
    mandatoryCustomization: mandatoryUserInputCustomisation,
    optionalCustomization: optionalUserInputCustomisation,
  }
}

export const getCustomConfigurationArrayV3 = (optionalOptionQuantityValues: any, productQuantityValues: ProductQuantity, eecomTpAssortmentProduct: EecomTpAssortmentProduct, product: Product | null, configuredLineItems: any) => {
  const mandatoryUserInputCustomisation = getUserInputCustomisation(_.get(eecomTpAssortmentProduct, "eecomTpOfferMandatoryOptions", []));
  const mandatoryPreFilledInputCustomisation = getPreFilledInputCustomisation(_.get(eecomTpAssortmentProduct, "eecomTpOfferMandatoryOptions", []));
  let optionalUserInputCustomisation = getUserInputCustomisation(_.get(eecomTpAssortmentProduct, "eecomTpOfferOptionalOptions", []));

  // Extract optionIds from eecomTpOfferOptionalOptions
  const customConfigurationArray: any = []
  if (product) {
    const children = sortChildren(product)
    if (children && children.length > 0) {
      children.forEach((child: Product) => {
        if (productQuantityValues && productQuantityValues[child.id] >= 0) {
          if (optionalOptionQuantityValues && Object.keys(optionalOptionQuantityValues).length > 0) {
            const updatedOptions = optionalUserInputCustomisation.map((option: any) => {
              const key = `${option.id}_${child.id}`;
              if (optionalOptionQuantityValues.hasOwnProperty(key)) {
                // Create a new object with updated optionQty
                return {
                  ...option,
                  optionQty: optionalOptionQuantityValues[key]
                };
              } else {
                return {
                  ...option,
                  optionQty: 0
                };
              }
            });
            // Replace the original array with the updated one
            optionalUserInputCustomisation = updatedOptions.slice();
          }
          customConfigurationArray.push(createChildProductV3(child, optionalOptionQuantityValues, productQuantityValues, configuredLineItems, mandatoryUserInputCustomisation, optionalUserInputCustomisation))
        }
      })
    } else {
      // If product is base product
      if (productQuantityValues && productQuantityValues[product.id] >= 0) {
        customConfigurationArray.push(createChildProductV3(product, optionalOptionQuantityValues, productQuantityValues, configuredLineItems, mandatoryUserInputCustomisation, optionalUserInputCustomisation))
      }
    }
  }
  if (customConfigurationArray && customConfigurationArray.length > 0) {
    customConfigurationArray.forEach((child: any) => {
      mandatoryPreFilledInputCustomisation.forEach((c: any) => {
        child.playerConfiguration.options[c.id] = {
          type: CONSTANT.PLAYER_CONFIGURATIONS.TYPE.PREFILLED_INPUT,
        }
      })
    })
  }
  return customConfigurationArray;
};


export const assortmentItemAlreadyInCart = (assortmentProductId: any, checkoutCartObj: any) => {
  let isAssortmentInCart = false
  const lineItems = _getValue(checkoutCartObj, "lineItems", []).value
  if (lineItems && lineItems.length > 0) {
    lineItems.forEach((item: any) => {
      if (item.id === assortmentProductId) {
        isAssortmentInCart = true
      }
    })
  }
  return isAssortmentInCart
}

export const offerAssortmentItemAlreadyInCart = (assortmentProductId: any, checkoutCartObj: any) => {
  let isAssortmentInCart = false
  const lineItems = _getValue(checkoutCartObj, "lineItems", []).value
  if (lineItems && lineItems.length > 0) {
    lineItems.forEach((item: any) => {
      if (item.id === assortmentProductId) {
        isAssortmentInCart = true
      }
    })
  }
  return isAssortmentInCart
}
export const getLineItemIdOfAssortmentItem = (assortmentProductId: any, checkoutCartObj: any) => {
  let lineItemId = ""
  const lineItems = _getValue(checkoutCartObj, "lineItems", []).value
  if (lineItems && lineItems.length > 0) {
    lineItems.forEach((item: any) => {
      if (item.id === assortmentProductId) {
        lineItemId = item.id
      }
    })
  }
  return lineItemId
}

export const getOfferLineItemIdOfAssortmentItem = (id: any, checkoutCartObj: any) => {
  let lineItemId = ""
  const lineItems = _getValue(checkoutCartObj, "lineItems", []).value
  if (lineItems && lineItems.length > 0) {
    lineItems.forEach((item: any) => {
      if (item.id === id) {
        lineItemId = item.id
      }
    })
  }
  return lineItemId
}
export const getTotalCountOfCart = (checkoutCartObj: Cart) => {
  let totalCount = 0
  const lineItems: LineItem[] = _getValue(checkoutCartObj, "lineItems", []).value
  if (lineItems && lineItems.length > 0) {
    totalCount = lineItems.length
  }
  return totalCount
}

export const getCalculatedPriceObject = (product: Product | null | undefined, totalCount: number) => {
  if (product) {
    if (product.calculatedPrices && product.calculatedPrices.length > 0) {
      let highestCalculatedQuantity = 0
      let highestCalculatedPriceObj = null
      for (let i = 0; i < product.calculatedPrices.length; i++) {
        if (i === 0) {
          highestCalculatedPriceObj = product.calculatedPrices[i]
        } else if (i > 0) {
          if (product.calculatedPrices[i].quantity <= totalCount && product.calculatedPrices[i].quantity >= highestCalculatedQuantity) {
            highestCalculatedQuantity = product.calculatedPrices[i].quantity
            highestCalculatedPriceObj = product.calculatedPrices[i]
          }
        }
      }
      return highestCalculatedPriceObj
    } else {
      return product.calculatedPrice || 0
    }
  } else {
    return {} as CalculatedPrice
  }
}

export const isDiscountPercentage = (product: any, customer: any, assortmentProduct: any, taxState: any) => {
  let discountPercentage: any = "0"
  let unitPrice: number = 0

  const customerRegularDiscount = _.get(customer, "customFields.eecom_tp_customer_regular_discount", 0)
  const assortmentProductPriceObj = _getValue(assortmentProduct, "price[0]", null).value
  const calculatedCheapestListPrice = _getValue(product, "calculatedCheapestPrice.listPrice.price", 0).value
  if (assortmentProductPriceObj) {
    const displayGross = _getValue(customer, "group.displayGross", false).value
    unitPrice = _getValue(assortmentProductPriceObj, `${displayGross ? "gross" : "net"}`, 0).value
    if (taxState === "gross") {
      unitPrice = _getValue(assortmentProductPriceObj, "gross", 0).value
    } else {
      unitPrice = _getValue(assortmentProductPriceObj, "net", 0).value
    }
    discountPercentage = findPercentageByDiscountedPrice(calculatedCheapestListPrice, unitPrice)
  } else if (calculatedCheapestListPrice) {
    discountPercentage = customerRegularDiscount
  }
  return discountPercentage
}
export const isDiscountPercentageWithOffer = (product: any, customer: any, offerProduct: any, taxState: any) => {
  let discountPercentage: any = "0"
  const customerRegularDiscount = _.get(customer, "customFields.eecom_tp_customer_regular_discount", 0)
  const unitPrice: number = _getValue(offerProduct, "calculatedPrice.unitPrice", null).value
  const calculatedCheapestListPrice = _getValue(product, "calculatedCheapestPrice.listPrice.price", 0).value

  if (unitPrice) {
    discountPercentage = findPercentageByDiscountedPrice(calculatedCheapestListPrice, unitPrice)
  } else if (calculatedCheapestListPrice) {
    discountPercentage = customerRegularDiscount
  }
  return discountPercentage
}
export const removeEmptyOptionalCustomisation = (finalConfigurationArray: any, lineItems: any, isAddToCart: boolean) => {
  if (finalConfigurationArray && finalConfigurationArray.children) {
    finalConfigurationArray.children.forEach((child: any) => {
      if (lineItems[child.playerConfiguration.id]) {
        child.optionalCustomisation.forEach((customisation: any) => {
          if (lineItems[child.playerConfiguration.id].options && lineItems[child.playerConfiguration.id].options[customisation.id]) {
            if (isAddToCart) {
              lineItems[child.playerConfiguration.id].options[customisation.id].values = lineItems[child.playerConfiguration.id].options[customisation.id].values.filter((item: any) => {
                return getValueLength(item.value) > 0
              })
            }
          }
        })
        child.mandatoryCustomisation.forEach((mCustomisation: any) => {
          if (lineItems[child.playerConfiguration.id].options && lineItems[child.playerConfiguration.id].options[mCustomisation.id]) {
            if (isAddToCart) {
              lineItems[child.playerConfiguration.id].options[mCustomisation.id].values = lineItems[child.playerConfiguration.id].options[mCustomisation.id].values.filter((item: any) => {
                return getValueLength(item.value) > 0
              })
            }
          }
        })
      }
    })
  }
  return lineItems
}

export const removeEmptyOptionalCustomizationV2 = (finalConfigurationArray: any, lineItems: any, isAddToCart: boolean) => {
  if (finalConfigurationArray && finalConfigurationArray.children) {
    finalConfigurationArray.children.forEach((child: any) => {
      if (lineItems[child.playerConfiguration.id]) {
        child.optionalCustomization.forEach((customisation: any) => {
          if (lineItems[child.playerConfiguration.id].options && lineItems[child.playerConfiguration.id].options[customisation.id]) {
            if (isAddToCart) {
              lineItems[child.playerConfiguration.id].options[customisation.id].values = lineItems[child.playerConfiguration.id].options[customisation.id].values.filter((item: any) => {
                return getValueLength(item.value) > 0
              })
            }
          }
        })
        child.mandatoryCustomization.forEach((mCustomisation: any) => {
          if (lineItems[child.playerConfiguration.id].options && lineItems[child.playerConfiguration.id].options[mCustomisation.id]) {
            if (isAddToCart) {
              lineItems[child.playerConfiguration.id].options[mCustomisation.id].values = lineItems[child.playerConfiguration.id].options[mCustomisation.id].values.filter((item: any) => {
                return getValueLength(item.value) > 0
              })
            }
          }
        })
      }
    })
  }
  return lineItems
}

export const prepareCartObject = (eecomTpAssortmentProduct: any, isUserConsent: boolean, isConfigurable: boolean, lineItems: any, finalConfigurationArray: any, isAddToCart: boolean) => {
  const assortmentProductId = _getValue(eecomTpAssortmentProduct, "id", "").value
  const cartObject = {
    items: [
      {
        id: assortmentProductId,
        type: CONSTANT.COMMON.LINE_ITEM.TYPE,
        referencedId: assortmentProductId,
        isUserConsent: isUserConsent,
        stackable: CONSTANT.COMMON.LINE_ITEM.STACKABLE,
        isConfigurableProduct: isConfigurable,
        removable: CONSTANT.COMMON.LINE_ITEM.REMOVABLE,
        quantity: CONSTANT.COMMON.LINE_ITEM.QUANTITY,
        lineItem: {},
      },
    ],
  }
  if (lineItems) {
    cartObject.items[0].lineItem = removeEmptyOptionalCustomizationV2(finalConfigurationArray, lineItems, isAddToCart)
  }
  return cartObject
}

export const prepareCartObjectInAssortment = async (
  eecomTpAssortmentProduct: any,
  isUserConsent: boolean,
  isConfigurable: boolean,
  isOnlyPrefilledCustomization: boolean,
  productQuantityValues: any,
  configuration: any,
  dbService: any
) => {
  const assortmentProductId = _getValue(eecomTpAssortmentProduct, "id", "").value
  const product = _getValue(eecomTpAssortmentProduct, "product", "").value
  const children = _getValue(eecomTpAssortmentProduct, "product.children", []).value
  const lineItems = configuration.lineItems
  const customConfigurationChildArray = getCustomConfigurationArrayV2(productQuantityValues, eecomTpAssortmentProduct, product, lineItems)
  let cartObject: any = {
    items: [
      {
        id: assortmentProductId,
        type: CONSTANT.COMMON.LINE_ITEM.TYPE,
        referencedId: assortmentProductId,
        isUserConsent: isUserConsent,
        stackable: CONSTANT.COMMON.LINE_ITEM.STACKABLE,
        isConfigurableProduct: isConfigurable,
        removable: CONSTANT.COMMON.LINE_ITEM.REMOVABLE,
        quantity: CONSTANT.COMMON.LINE_ITEM.QUANTITY,
        lineItem: {},
      },
    ],
  }
  if (isOnlyPrefilledCustomization) {
    cartObject.items[0]["isConfigurableProduct"] = true
  }

  if (children && children.length !== 0) {
    Object.keys(productQuantityValues).forEach((key: string) => {
      if (productQuantityValues[key] > 0) {
        cartObject.items[0].lineItem[key] = {
          quantity: productQuantityValues ? productQuantityValues[key] : CONSTANT.COMMON.DEFAULT_PRODUCT_SIZE,
          id: key,
          type: "product",
          referencedId: key,
          stackable: CONSTANT.COMMON.LINE_ITEM.STACKABLE,
          removable: CONSTANT.COMMON.LINE_ITEM.REMOVABLE,
        }
      }
    })
  } else {
    if (product && product.id) {
      cartObject.items[0].lineItem[product.id] = {
        quantity: productQuantityValues ? productQuantityValues[product.id] : CONSTANT.COMMON.DEFAULT_PRODUCT_SIZE,
        id: product.id,
        type: "product",
        referencedId: product.id,
        stackable: true,
        removable: true,
      }
    }
  }

  if (customConfigurationChildArray && customConfigurationChildArray.length > 0) {
    customConfigurationChildArray.forEach((child: any) => {
      cartObject.items[0].lineItem[child.id] = child.playerConfiguration
    })
  }

  if (isConfigurable) {
    const result = await dbService.get("offerAssortmentItemCustomerObject", eecomTpAssortmentProduct.id)
    const conf = JSON.parse(_getValue(result, "configuration", "{}").value)
    if (conf && conf.items && conf.items.length > 0) {
      cartObject = conf
    } else {
      await dbService.delete("offerAssortmentItemCustomerObject", eecomTpAssortmentProduct.id)
    }
  }

  if (cartObject && cartObject.items[0].lineItem && Object.keys(cartObject.items[0].lineItem).length > 0) {
    Object.keys(cartObject.items[0].lineItem).forEach((key: string) => {
      const _quantity = cartObject.items[0].lineItem[key].quantity || 0
      if (cartObject.items[0].lineItem[key] && _quantity === 0) {
        delete cartObject.items[0].lineItem[key]
      }
    })
  }
  return cartObject
}

export const prepareCartObjectInOffer = async (
  eecomTpAssortmentProduct: any,
  isUserConsent: boolean,
  isConfigurable: boolean,
  isOnlyPrefilledCustomization: boolean,
  productQuantityValues: any,
  configuration: any,
  dbService: any,
  dbStoreName: string
) => {
  const assortmentProductId = _getValue(eecomTpAssortmentProduct, "eecomTpAssortmentProduct.id", "").value
  const product = _getValue(eecomTpAssortmentProduct, "eecomTpAssortmentProduct.product", "").value
  const children = _getValue(eecomTpAssortmentProduct, "eecomTpAssortmentProduct.product.children", []).value
  const lineItems = configuration.lineItem
  const customConfigurationChildArray = getCustomConfigurationArrayV3({}, productQuantityValues, eecomTpAssortmentProduct, product, lineItems)
  let cartObject: any = {
    items: [
      {
        id: assortmentProductId,
        type: CONSTANT.COMMON.LINE_ITEM.TYPE,
        referencedId: assortmentProductId,
        isUserConsent: isUserConsent,
        stackable: CONSTANT.COMMON.LINE_ITEM.STACKABLE,
        isConfigurableProduct: isConfigurable,
        removable: CONSTANT.COMMON.LINE_ITEM.REMOVABLE,
        quantity: CONSTANT.COMMON.LINE_ITEM.QUANTITY,
        lineItem: {},
      },
    ],
  }
  if (isOnlyPrefilledCustomization) {
    cartObject.items[0]["isConfigurableProduct"] = true
  }

  if (children && children.length !== 0) {
    Object.keys(productQuantityValues).forEach((key: string) => {
      if (productQuantityValues[key] > 0) {
        cartObject.items[0].lineItem[key] = {
          quantity: productQuantityValues ? Number(productQuantityValues[key]) : CONSTANT.COMMON.DEFAULT_PRODUCT_SIZE,
          id: key,
          type: "product",
          referencedId: key,
          stackable: CONSTANT.COMMON.LINE_ITEM.STACKABLE,
          removable: CONSTANT.COMMON.LINE_ITEM.REMOVABLE,
        }
      }
    })
  } else {
    if (product && product.id) {
      cartObject.items[0].lineItem[product.id] = {
        quantity: productQuantityValues ? Number(productQuantityValues[product.id]) : CONSTANT.COMMON.DEFAULT_PRODUCT_SIZE,
        id: product.id,
        type: "product",
        referencedId: product.id,
        stackable: true,
        removable: true,
      }
    }
  }

  if (customConfigurationChildArray && customConfigurationChildArray.length > 0) {
    customConfigurationChildArray.forEach((child: any) => {
      cartObject.items[0].lineItem[child.id] = child.playerConfiguration
    })
  }

  if (isConfigurable) {
    const result = await dbService.get(dbStoreName, eecomTpAssortmentProduct.id)
    const conf = JSON.parse(_getValue(result, "configuration", "{}").value)
    if (conf && conf.items && conf.items.length > 0) {
      cartObject = conf
    } else {
      await dbService.delete(dbStoreName, eecomTpAssortmentProduct.id)
    }
  }

  if (cartObject && cartObject.items[0].lineItem && Object.keys(cartObject.items[0].lineItem).length > 0) {
    Object.keys(cartObject.items[0].lineItem).forEach((key: string) => {
      const _quantity = cartObject.items[0].lineItem[key].quantity || 0
      if (cartObject.items[0].lineItem[key] && _quantity === 0) {
        delete cartObject.items[0].lineItem[key]
      }
    })
  }
  return cartObject
}
export const getValueLength = (value: any) => {
  if (value === undefined || value === null) {
    return 0
  } else if (typeof value === "number") {
    return value.toString().length
  } else {
    return value ? value.toString().length : 0
  }
}

export const getTotalSumOfData = (dataObjects: PriceProduct[], key: keyof Price) => {
  return dataObjects.reduce((total: any, { price }) => {
    return total + price[key]
  }, 0)
}
export const getTotalSumOfDataForOrder = (dataObjects: Price[], key: keyof Price) => {
  return dataObjects.reduce((total: any, Obj) => {
    return total + Obj[key]
  }, 0)
}
export const getCustomisationName = (customisation: any) => {
  return _getValue(
    customisation,
    "eecomTpCustomization.stockProduct.options[0].name ",
    _getValue(customisation, "eecomTpCustomization.stockProduct.options[0].translated.name", CONSTANT.COMMON.FIELD_NULL).value
  ).value
}

export function findUnitPrice(calculatedPrices: CalculatedPrice[], quantity: number): number | null {
  const calculatedPrice = calculatedPrices.find((price) => {
    return quantity <= price.quantity
  })
  return calculatedPrice ? calculatedPrice.unitPrice : calculatedPrices[calculatedPrices.length - 1].unitPrice
}

export const getOptionPrice = (option: any, qty: number) => {
  if (option && option.calculatedPrice) {
    return option.calculatedPrice.unitPrice
  } else if (option.eecomTpCustomization.stockProduct) {
    if (option.eecomTpCustomization.stockProduct.calculatedPrices && option.eecomTpCustomization.stockProduct.calculatedPrices.length) {
      return findUnitPrice(option.eecomTpCustomization.stockProduct.calculatedPrices, qty)
    } else {
      return option.eecomTpCustomization.stockProduct.calculatedPrice?.unitPrice || 0
    }
  }
}
export const getTotalValue = (count: number, price: number) => {
  return Number((count * price).toFixed(CONSTANT.COMMON.FRACTION_DIGITS))
}
export const manageCustomisationFinishesByType = (customisation: any, totalPlayerCount: any, playerNameArray: any, initialsArray: any, numberArray: any, expertModeArray: any) => {
  const tempCustomisationArray: any = []
  customisation.forEach((customisation: any, index: number) => {
    const label = getCustomisationName(customisation)
    const c = customisation.eecomTpCustomization
    let price = 0
    let count = 0
    let total = 0
    const id = `m_${index}`
    if (c.type) {
      if (c.type !== CONSTANT.PLAYER_CONFIGURATIONS.TYPE.USER_INPUT) {
        count = totalPlayerCount
      } else {
        if (!c.expertMode) {
          if (CONSTANT.PLAYER_CONFIGURATIONS.REGEX.PLAYER_NAME === c.validationRule) {
            count = Object.keys(playerNameArray).length
          }
          if (CONSTANT.PLAYER_CONFIGURATIONS.REGEX.INITIALS === c.validationRule) {
            count = Object.keys(initialsArray).length
          }
          if (CONSTANT.PLAYER_CONFIGURATIONS.REGEX.NUMBER === c.validationRule) {
            count = Object.keys(numberArray).length
          }
        } else {
          count = Object.keys(expertModeArray).length
        }
      }
    } else {
      count = totalPlayerCount
    }
    price = getOptionPrice(customisation, count)
    total = getTotalValue(count, price)
    tempCustomisationArray.push({
      count,
      label,
      price,
      total,
      id,
    })
  })
  return tempCustomisationArray
}

export const isInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + 60 &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) + 60
  )
}
export const getSumOfTwoNumbersWithFractionDigits = (a: number, b: number, fractionDigits: number) => {
  return Number((a + b).toFixed(fractionDigits))
}
export const getMultiplicationOfTwoNumbersWithFractionDigits = (a: number, b: number, fractionDigits: number) => {
  return Number((a * b).toFixed(fractionDigits))
}
/**
 * @description This function is used to download csv file
 * @param fileName
 * @param csvContent
 * @returns void
 */
export const downloadCSV = (fileName: string, csvContent: string) => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
  const link = document.createElement("a")
  const url = URL.createObjectURL(blob)
  link.setAttribute("href", url)
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const getFormattedDeliveryTime = (deliveryTimeText: string, dispoDate: string) => {
  let formattedDeliveryTimeText = deliveryTimeText
  //dispoDate = dispoDate ? getDateFormatted(dispoDate) : CONSTANT.COMMON.FIELD_NULL;
  let dispoDateText = null
  if (dispoDate) {
    dispoDateText = new Date(dispoDate).toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
  } else {
    dispoDateText = CONSTANT.COMMON.FIELD_NULL
  }
  if (formattedDeliveryTimeText && formattedDeliveryTimeText.includes("[date]")) {
    formattedDeliveryTimeText = deliveryTimeText.replace("[date]", dispoDateText)
  }
  return formattedDeliveryTimeText
}
// Get the page name based on your application's logic
export const getPageName = (to: RouteLocationNormalized): string | RouteRecordName => {
  // Logic to determine the page name based on the route or any other criteria
  // Example: return to.name for named routes
  return to.name || ""
}
// Triggered on page view
export const trackPageView = (pageName: string | RouteRecordName, gtmContainerId: string) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "pageview",
    "gtm.start": new Date().getTime(),
    "gtm.uniqueEventId": 1,
    "gtm.containerId": gtmContainerId,
    "gtm.url": window.location.href,
    "gtm.pageTitle": document.title,
    "gtm.pageName": pageName,
  })
}

// Triggered on add to cart event
export const trackAddToCart = (gtmContainerId: string) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "addToCart",
    "gtm.start": new Date().getTime(),
    "gtm.uniqueEventId": 2,
    "gtm.containerId": gtmContainerId,
  })
}

interface CookieEntry {
  snippet_name: string
  snippet_description?: string
  isRequired?: boolean
  entries?: CookieEntry[]
  accepted?: boolean
}

export const updateGoogleTagManagerConsent = (acceptedEntries: CookieEntry[]) => {
  // Modify this function based on your implementation of Google Tag Manager
  // You can use the acceptedEntries to update the consent in Google Tag Manager

  // Example implementation:
  const gtmDataLayer = window.dataLayer || []
  gtmDataLayer.push({
    event: "cookieConsentUpdate",
    consent: acceptedEntries,
  })
}

export const getUserDisplayName = (customer: any) => {
  const fullName: any = [_.get(customer, `firstName`, ""), _.get(customer, `lastName`, "")]
  return `${[].concat(fullName).join(" ")}`
}

export const isCheckSalesRep = (customer: any) => {
  return _.get(customer, "customFields.eecom_tp_is_sales_rep", false) || _.get(customer, "customFields.eecom_tp_is_super_sales_rep", false)
}

export const getSalesRepName = (customer: any) => {
  const salesRepContact = _.get(customer, "extensions.eecomTpContactPerson[0].salesRepresentative", "")
  const fullName: any = [_.get(salesRepContact, `firstName`, ""), _.get(salesRepContact, `lastName`, "")]
  return `${[].concat(fullName).join(" ")}`
}

export const getSalesRepTel = (customer: any) => {
  const salesRepContact = _.get(customer, "extensions.eecomTpContactPerson[0].salesRepresentative", "")
  const phoneNumber: any = _.get(salesRepContact, `defaultBillingAddress.phoneNumber`, "")
  return phoneNumber
}

export const getCustomerName = (customer: any) => {
  const fullName: any = [_.get(customer, `contact.firstName`, ""), _.get(customer, `contact.lastName`, "")]
  return `${[].concat(fullName).join(" ")}`
}

export const customerNavItems = [
  { name: CONSTANT.ROUTE.NAME.DASHBOARD_CUSTOMER },
  { name: CONSTANT.ROUTE.NAME.TEAM_ORDER },
  { name: CONSTANT.ROUTE.NAME.OFFER },
  { name: CONSTANT.ROUTE.NAME.ORDERS },
  { name: CONSTANT.ROUTE.NAME.CONTRACTS_DOCUMENTS },
  // { name: CONSTANT.ROUTE.NAME.MEDIA }
]
export const salesForceNavItems = [
  {
    name: CONSTANT.ROUTE.NAME.DASHBOARD_SALES_FORCE,
    isEnable: true,
    childRoutesName: [
      CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_CREATE,
      CONSTANT.ROUTE.NAME.DASHBOARD_SALES_FORCE,
      CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_BASE,
      CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_ADDRESSES,
      CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_ORDER,
      CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_ORDER_DETAIL,
      CONSTANT.ROUTE.NAME.SALES_FORCE_CUSTOMER_DATA_DOCUMENTS,
      CONSTANT.ROUTE.NAME.SALES_FORCE_ORDER_CATALOGUE,
      CONSTANT.ROUTE.NAME.SALES_FORCE_PRODUCT_RANGE,
    ],
  },
  // {name: CONSTANT.ROUTE.NAME.CAMPAGIN,isEnable: false, childRoutesName: [CONSTANT.ROUTE.NAME.CAMPAGIN]},
]

export const imageUrl = (path: string): string => {
  return imageService.getSmallImage(path)
}

export const imageMediumUrl = (path: string): string => {
  return imageService.getMediumImage(path)
}

export const imageLargeUrl = (path: string): string => {
  return imageService.getLargeImage(path)
}
export const isValidationErrorInExpertMode = (inputValue: string, regex: RegExp | null) => {
  let isValidationError: boolean
  if (regex) {
    const matchedResult = inputValue?.match(regex)
    // Check if the entire string is a match
    isValidationError = matchedResult ? matchedResult.join("").length !== inputValue.length : true
  } else {
    isValidationError = true
  }
  return isValidationError
}
export const initRegExForExpertMode = (validationRule: string, defaultRegExFlagToValidateWholeString = "") => {
  let regexFlags = validationRule?.replace(/.*\/([gimuy]*)$/, "$1") // Extract flags if any
  const isValidFlags = regexFlags?.match(/^[gimuy]*$/)
  if (!isValidFlags) {
    regexFlags = defaultRegExFlagToValidateWholeString
  }
  const regexStringPattern = validationRule?.replace(/(^\/)|(\/[gimuy]*$)/g, "") // Extract pattern
  return createRegex(regexStringPattern, regexFlags)
}
// Create a regex object with the specified pattern
export const createRegex = (pattern: RegExp | string, flags = "g") => {
  try {
    return new RegExp(pattern, flags)
  } catch (error) {
    console.error("Invalid regular expression pattern:", error)
    return null
  }
}

export const getExpertModeLabel = (option: any) => {
  const { t } = useI18n()
  return _getValue(option, "translated.name", t("eecom-teamportal.playerConfiguration.initials")).value
}

export const defaultLanguageId = (languageList: Language[]) => {
  const defaultLanguageLocale = getDefaultLanguage()
  const defaultLanguage = languageList.find((lang) => lang.translationCode?.code.includes(defaultLanguageLocale))
  return defaultLanguage?.id
}

export function getLanguageIsoCode(language: Language) {
  return _.get(language, "translationCode.code", "")
}

export function getTranslationObject(translations: any, currentLanguageId: string, defaultLanguageId: string, translationValue: string) {
  const translationObj: Translations = {}
  if (translations && translations.length > 0) {
    // Case 1: currentlang and defaultlag same, currentlang already in translations
    if (currentLanguageId === defaultLanguageId && translations.find((translation: any) => translation.languageId == currentLanguageId)) {
      translationObj[currentLanguageId] = { name: translationValue }
    }

    // Case 2: currentlang and defaultlag different, currentlang already in translations
    else if (currentLanguageId !== defaultLanguageId && !translations.find((translation: any) => translation.languageId === defaultLanguageId)) {
      translationObj[currentLanguageId] = { name: translationValue }
      translationObj[defaultLanguageId] = { name: translationValue }
    }

    // Case 3: currentlang and defaultlag different, defaultlang already in translations
    else if (currentLanguageId !== defaultLanguageId && translations.find((translation: any) => translation.languageId !== currentLanguageId)) {
      translationObj[currentLanguageId] = { name: translationValue }
    }
  } else {
    if (currentLanguageId !== defaultLanguageId) {
      translationObj[currentLanguageId] = { name: translationValue }
      translationObj[defaultLanguageId] = { name: translationValue }
    } else {
      translationObj[currentLanguageId] = { name: translationValue }
    }
  }
  return translationObj
}

export const getTranslationsForOrderCatalogue = (languageList: Language[], rangeName: string) => {
  const translations: any = {}
  languageList.forEach((language) => {
    const { id } = language
    if (id) {
      translations[id] = {
        name: rangeName,
      }
    }
  })
  return translations
}

// Common Functions for Teamportal V2 version
export const getEEcomTpAssortmentConfiguration = (product: any) => {
  const lastIndex = get(product, "eecomTpAssortmentConfiguration.length", 1) - 1
  const configurationPath = `eecomTpAssortmentConfiguration[${lastIndex}].configuration`
  const updatedAtPath = `eecomTpAssortmentConfiguration[${lastIndex}].updatedAt`

  return {
    configuration: get(product, configurationPath, {}),
    updatedAt: get(product, updatedAtPath, 0),
    serverTimestamp: new Date(get(product, updatedAtPath, 0)).getTime(),
  }
}
export const getEEcomTpofferAssortmentConfiguration = (product: any) => {
  // const lastIndex = get(product, "eecomTpAssortmentConfiguration.length", 1) - 1
  // const configurationPath = `eecomTpAssortmentConfiguration[${lastIndex}].configuration`
  // const updatedAtPath = `eecomTpAssortmentConfiguration[${lastIndex}].updatedAt`

  return {
    configuration: get(product, 'configuration', {}),
    // updatedAt: get(product, updatedAtPath, 0),
    // serverTimestamp: new Date(get(product, updatedAtPath, 0)).getTime(),
  }
}
export const parseConfigurationFromItemObject = (assortmentItemObject: any): Configuration => {
  const configuration = JSON.parse(_getValue(assortmentItemObject, "configuration", "{}").value)
  return _getValue(configuration, `items[0]`, null).value
}

export const getAllConfigurationsFromIndexDB = async () => {
  const dbService = new IndexedDbService("playerConfiguration", 1, [
    {
      name: "assortmentItemObject",
      options: { keyPath: "id", autoIncrement: false },
    },
  ])
  await dbService.connectWithIndexedDb()
  const result = await dbService.getAll("assortmentItemObject")
  return result
}

export const getConfigurationFromIndexDB = async (assortmentProductId: string, dbService: IndexedDbService): Promise<AssortmentItemObject> => {
  await dbService.connectWithIndexedDb()
  const result = await dbService.get("assortmentItemObject", assortmentProductId)
  const indexDBUpdatedAt = JSON.parse(_getValue(result, "updatedAt", 0).value)
  const indexDBUpdatedAtTimestamp = new Date(indexDBUpdatedAt).getTime()

  return {
    ...result,
    updatedAt: indexDBUpdatedAtTimestamp,
  }
}

export const setConfigurationFromIndexDB = async (assortmentProductId: string, configuration: any, updatedAt: number) => {
  const dbService = new IndexedDbService("playerConfiguration", 1, [
    {
      name: "assortmentItemObject",
      options: { keyPath: "id", autoIncrement: false },
    },
  ])
  await dbService.connectWithIndexedDb()
  const obj = { id: assortmentProductId, configuration: JSON.stringify(configuration), updatedAt: updatedAt }
  await dbService.set("assortmentItemObject", obj)
}

export const removeConfigurationFromIndexDB = async (dbName: string, version: number, storeName: string) => {
  const dbService = new IndexedDbService(dbName, version, [
    {
      name: storeName,
      options: { keyPath: "id", autoIncrement: false },
    },
  ])
  await dbService.connectWithIndexedDb()
  dbService.clearIndexDB(storeName)
}

export const createOptionsValues = (customisations: any, productQuantityValues: any, configuredLineItems: CartLineItem, childId: string) => {
  const options: any = {}
  const lineItems = configuredLineItems[childId]

  customisations.forEach((customisation: any) => {
    const isPrefilled = customisation.eecomTpCustomization.type === CONSTANT.PLAYER_CONFIGURATIONS.TYPE.PREFILLED_INPUT
    if (isPrefilled) {
      options[customisation.id] = {
        type: CONSTANT.PLAYER_CONFIGURATIONS.TYPE.PREFILLED_INPUT,
      }
    } else {
      options[customisation.id] = {
        type: CONSTANT.PLAYER_CONFIGURATIONS.TYPE.USER_INPUT,
        values: [],
      }

      if (lineItems) {
        for (let i = 0; i < productQuantityValues[childId]; i++) {
          let value: any = ""

          if (lineItems.options && lineItems.options[customisation.id] && lineItems?.options[customisation.id].values[i]) {
            const existValue = lineItems?.options[customisation.id].values[i].value
            const existAmount = lineItems?.options[customisation.id].values[i].amount
            value = existAmount === i + 1 && existValue ? existValue : ""
          } else {
            value = ""
          }

          options[customisation.id].values.push({
            amount: i + 1,
            value,
          })
        }
      }
    }
  })
  return options
}

export const getCustomizationNameFromOption = (option: any, isMandatory: boolean, t: any) => {
  const validationRule = _getValue(option, "validationRule", null).value
  let customizationLabel = ""
  let customizationType = ""
  if (!option?.expertMode) {
    switch (validationRule) {
      case CONSTANT.PLAYER_CONFIGURATIONS.REGEX.PLAYER_NAME:
        customizationType = "playerName"
        customizationLabel = `${t("eecom-teamportal.playerConfiguration.playerName")} ${isMandatory ? "*" : ""}`
        break
      case CONSTANT.PLAYER_CONFIGURATIONS.REGEX.NUMBER:
        customizationType = "number"
        customizationLabel = `${t("eecom-teamportal.playerConfiguration.number")} ${isMandatory ? "*" : ""}`
        break
      case CONSTANT.PLAYER_CONFIGURATIONS.REGEX.INITIALS:
        customizationType = "initials"
        customizationLabel = `${t("eecom-teamportal.playerConfiguration.initials")} ${isMandatory ? "*" : ""}`
        break
      default:
        break
    }
  } else {
    customizationType = "expertMode"
    customizationLabel = `${_getValue(option, "translated.name", t("eecom-teamportal.playerConfiguration.defaultExpertModeLabel")).value} ${isMandatory ? "*" : ""}`
  }
  return { customizationLabel, customizationType }
}

// const getTranslationCodeList = (languageList: Language[]) => {
//   return languageList.map((element) => element?.translationCode?.code)
// }

export const isListPrice = (product: Product) => {
  return !!_getValue(product, "calculatedCheapestPrice.listPrice", null)?.value
}

export const checkOnlyPrefilledCustomization = (assortmentProduct: EecomTpAssortmentProduct) => {
  const mandatoryCustomizations = assortmentProduct?.eecomTpMandatoryCustomizations
  return mandatoryCustomizations?.length > 0 && mandatoryCustomizations.every((customisation: any) => customisation.eecomTpCustomization.type === CONSTANT.PLAYER_CONFIGURATIONS.TYPE.PREFILLED_INPUT)
}
export const checkOnlyPrefilledCustomizationV3 = (assortmentProduct: EecomTpAssortmentProduct) => {
  const mandatoryCustomizations = assortmentProduct?.eecomTpOfferMandatoryOptions || []
  return mandatoryCustomizations?.length > 0 && mandatoryCustomizations.every((customisation: any) => customisation.eecomTpCustomization.type === CONSTANT.PLAYER_CONFIGURATIONS.TYPE.PREFILLED_INPUT)
}

export const checkConfigurable = (assortmentProduct: EecomTpAssortmentProduct) => {
  const hasOptionalCustomizations = assortmentProduct?.eecomTpOptionalCustomizations?.length > 0
  const hasMandatoryCustomizations = assortmentProduct?.eecomTpMandatoryCustomizations?.length > 0 && !checkOnlyPrefilledCustomization(assortmentProduct)

  return hasOptionalCustomizations || hasMandatoryCustomizations
}
export const checkConfigurableV3 = (assortmentProduct: EecomTpAssortmentProduct) => {
  const hasOptionalCustomizations = assortmentProduct?.eecomTpOfferOptionalOptions?.length > 0
  const hasMandatoryCustomizations = assortmentProduct?.eecomTpOfferMandatoryOptions?.length > 0 && !checkOnlyPrefilledCustomizationV3(assortmentProduct)
  return hasOptionalCustomizations || hasMandatoryCustomizations
}

export const getStockStatusClass = (availableStock: any, reportingStock: any, isTeamportalDispoStock:boolean, isPartnerProgramm: boolean) => {
  if(isPartnerProgramm){
    return "out-of-stock"
  }

  if(availableStock > 0 && availableStock > reportingStock){
      return "stock-available"
  }

  if(availableStock > 0 && availableStock <= reportingStock){
      return "limited-stock"
  }

  if(isTeamportalDispoStock){
      return "limited-stock"
  }
  return "out-of-stock"
}



export const getAdidasStockStatusClass = (matchedDeliveryTime: Object, availableStock: any, reportingStock: any, hasContractWarehouseDeliveryTime: boolean,child:any) => {
  const dispoDate = _.get(matchedDeliveryTime, "dispoDate", "")
  const dispoDateIsSoon = isDateSoon(dispoDate, CONSTANT.COMMON.DAYS_THRESHOLD)
  if (availableStock <= 0 || (isValidDate(dispoDate) && !dispoDateIsSoon)) {
    return "out-of-stock"
  } else if (availableStock > reportingStock) {
    if (hasContractWarehouseDeliveryTime || dispoDateIsSoon) {
      return "limited-stock"
    } else {
      return "stock-available"
    }
  } else {
    return "limited-stock"
  }
}

export const getTrafficLightTooltip = (availableStock: number, reportingStock: number, deliveryTimeExtensionText: string, t: (k:string) => string, isTeamportalDispoStock:boolean, isPartnerProgramm: boolean) => {
  let key: string = 'outOfStock';

  switch(true){
    case isPartnerProgramm:
      key = 'outOfStock';
      break;
    case isTeamportalDispoStock:
      key = 'inStockLessThanReportingStockAvailable';
      break;
    case (availableStock > 0 && availableStock > reportingStock):
      key = 'inStockMoreThanReportingStockAvailable';
      break;
    case (availableStock > 0 && availableStock <= reportingStock):
      key = 'inStockLessThanReportingStockAvailable';
      break;
  }

  return t("eecom-teamportal.teamOrder.trafficLightTooltip." + key)
    .replace(new RegExp("%REPORTING_STOCK%", "g"), reportingStock.toString())
    .replace(new RegExp("%DELIVERY_TIME%", "g"), deliveryTimeExtensionText)
}

export const getAdidasTrafficLightTooltip = (matchedDeliveryTime: Object, availableStock: any, reportingStock: any, deliveryTimeExtensionText: string, hasContractWarehouseDeliveryTime: boolean, t: any,child:any) => {
  let tooltip = ""
  const dispoDate = _.get(matchedDeliveryTime, "dispoDate", "")
  const dispoDateIsSoon = isDateSoon(dispoDate, CONSTANT.COMMON.DAYS_THRESHOLD) //dispoDate && dayjs(dispoDate).isBefore(dayjs().add(CONSTANT.COMMON.DAYS_THRESHOLD, 'day'));
  const partnerProgram = !!child?.customFields?.eecom_product_affiliate_id;
  if (availableStock <= 0 || (isValidDate(dispoDate) && !dispoDateIsSoon) || partnerProgram) {
    tooltip = t("eecom-teamportal.teamOrder.trafficLightTooltip.outOfStock")
    if (!dispoDateIsSoon) {
      tooltip = tooltip
    } else {
      tooltip = tooltip.split("/")[0] || "" // Extract the delivery time extension text for tooltip without date;
    }
    return tooltip.replace(new RegExp("%DELIVERY_TIME%", "g"), deliveryTimeExtensionText)
  }

  if (availableStock > reportingStock) {
    tooltip = t("eecom-teamportal.teamOrder.trafficLightTooltip.inStockMoreThanReportingStockAvailable")
    return tooltip.replace(new RegExp("%REPORTING_STOCK%", "g"), reportingStock)
  } else {
    tooltip = t("eecom-teamportal.teamOrder.trafficLightTooltip.inStockLessThanReportingStockAvailable")
  }
  if (dispoDateIsSoon) {
    tooltip = tooltip.replace(new RegExp("%REPORTING_STOCK%", "g"), reportingStock)
  } else {
    tooltip = tooltip.split("/")[0]?.replace(new RegExp("%REPORTING_STOCK%", "g"), reportingStock) || "" // Extract the delivery time extension text
  }
  return tooltip.replace(new RegExp("%DELIVERY_TIME%", "g"), deliveryTimeExtensionText)
}

export const isValidDate = (date: string) => {
  if (!date) return false

  const today = dayjs()
  const targetDate = dayjs(date)

  return targetDate.isAfter(today)
}
export const isDateSoon = (dispoDate: string, daysAhead: number) => {
  if (!isValidDate(dispoDate)) return false

  const today = dayjs()
  const targetDate = dayjs(dispoDate)
  const endDate = today.add(daysAhead, "day")

  return targetDate.isBefore(endDate)
}
export const setInitialLineItemObj = (productId: string, quantity: number) => {
  const lineItemObj = {
    id: productId,
    type: CONSTANT.COMMON.LINE_ITEM.PRODUCT_TYPE,
    referencedId: productId,
    stackable: CONSTANT.COMMON.LINE_ITEM.STACKABLE,
    removable: CONSTANT.COMMON.LINE_ITEM.REMOVABLE,
    quantity: quantity,
    options: {},
  }
  return lineItemObj
}

export const getDateTimeFormatted = (_date: any) => {
  const formattedDate = getDateFormatted(_date)
  const formattedTime = getTimeFormatted(_date)
  return formattedDate && formattedTime ? [formattedDate, formattedTime].join(" | ") : "-"
}

export const decimalValue = (amount: number) => Math.round(amount * 100) / 100
export const createFilterAssortmentProduct = (search: string) => {
  const trimmedSearchQuery = search.trim()

  const lowerSearchParts = trimmedSearchQuery
    .toLowerCase()
    .split(/\s+/)
    .filter((s) => s.length > 0)

  if (lowerSearchParts.length === 0) {
    return () => true
  }

  const matchesSearch = (s: string): boolean => {
    const sParts = s
      .toLowerCase()
      .split(/\s+/)
      .filter((s_) => s_.length > 0)
    return lowerSearchParts.every((p) => p.length === 0 || sParts.some((sp) => sp.startsWith(p) || sp.endsWith(p)))
  }

  return (assortmentProduct: any) => {
    const { name, translated, product } = assortmentProduct
    return (
      (name && matchesSearch(name)) ||
      (translated.name && matchesSearch(translated.name)) ||
      (product.translated.name && matchesSearch(product.translated.name)) ||
      (product.productNumber && matchesSearch(product.productNumber)) ||
      (product.manufacturerNumber && matchesSearch(product.manufacturerNumber))
    )
  }
}

export const getListSearchFilter = (value: string, listSearchQuery: any) => {
  const searchType = CONSTANT.BODY_PARAMS.SEARCH_QUERY.TYPE
  const searchQuery = [] as SearchQueryObject[]
  listSearchQuery.map((field: any) =>
    searchType.map((type) => {
      searchQuery.push(getSearchQueryObject(value, type, field))
    })
  )
  return searchQuery
}
