import { CONSTANT } from "@/helpers/constant";

const customer = ({ to, authedUser }: MiddlewareContext) => {
  const isSalesRep = authedUser?.customFields?.eecom_tp_is_sales_rep || false
  
  if(isSalesRep && to.path.startsWith(CONSTANT.ROUTE.PATH.DASHBOARD_CUSTOMER)){
    return false;
  }

  return true
}

export default customer
