import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import {useAuthStore, useCustomerStore} from "@/stores";
import loginRoutes from "@/router/login.routes";
import customerRoutes from "@/router/customer.routes";
import salesForceRoutes from "@/router/sales-force.routes";
import campaginRoutes from "@/router/campagin.routes";
import accountRoutes from "@/router/account.routes";
import { resolveMiddleware } from '@/router/middleware';

const routes: Array<RouteRecordRaw> = [
  {...loginRoutes},
  {...customerRoutes},
  {...salesForceRoutes},
  {...campaginRoutes},
  {...accountRoutes},
  {path: '/:pathMatch(.*)*', redirect: '/'}
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  document.title = (to.meta.title as string) || process.env['VUE_APP_NAME'];
  let metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", (to.meta.description as string) || '');
  }

  // Manage navigation guards
  const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
  if (!to.meta.middleware || !middleware) return true // Proceed if no middleware

  const authStore = useAuthStore()
  const customerStore = useCustomerStore()

  const context: MiddlewareContext = {
    to,
    from,
    router,
    contextToken: authStore.contextToken,
    authedUser: customerStore.customer,
  }
  return await resolveMiddleware(middleware, context)
})
