import { CONSTANT } from "@/helpers/constant";

const salesforce = ({ to, authedUser }: MiddlewareContext) => {
  const isSalesRep = authedUser?.customFields?.eecom_tp_is_sales_rep || false
  
  if(!isSalesRep && to.path.startsWith(CONSTANT.ROUTE.PATH.DASHBOARD_SALES_FORCE)){
    return false;
  }
  return true
}

export default salesforce
